import { Flex, DialogHeader, Button, Text } from "@tycholabs/armillary";
import { Dispatch, SetStateAction, useState } from "react";
import { Note } from "../../../../constants/Types";
import { UseDeletePrivateNote } from "../../../../hooks/notes";

export interface DeletePrivateNoteModalProps {
  setDeletePrivateNoteModalOpen: Dispatch<SetStateAction<boolean>>;
  setToastVisible: Dispatch<SetStateAction<boolean>>;
  setToastText: Dispatch<SetStateAction<string>>;
  queryRefetch: any;
  note: Note | null;
}

export const DeletePrivateNoteModal = ({
  setDeletePrivateNoteModalOpen,
  setToastVisible,
  setToastText,
  queryRefetch,
  note,
}: DeletePrivateNoteModalProps) => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const deletePrivateNote = UseDeletePrivateNote(
    userId ?? "",
    businessId ?? "",
    note ? note.lessonId : "",
    {
      id: note ? note.id : "",
    },
    session ?? ""
  );

  const handleSubmitButtonClick = () => {
    deletePrivateNote.mutateAsync().then((res) => {
      setIsSubmitting(true);
      if (res.data === "Success") {
        queryRefetch();
        setDeletePrivateNoteModalOpen(false);
        setToastText("Private note removed!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <DialogHeader title={"Remove Private Note?"} />
      <Text>This action cannot be undone.</Text>
      <Flex direction="row" gap="30px">
        <Button
          type={"secondary"}
          width={"100px"}
          onClick={() => setDeletePrivateNoteModalOpen(false)}
        >
          Cancel
        </Button>
        <Button
          type={"destructive"}
          width={"100px"}
          loading={isSubmitting}
          onClick={() => handleSubmitButtonClick()}
        >
          Delete
        </Button>
      </Flex>
    </>
  );
};

export default DeletePrivateNoteModal;
