import {
  Flex,
  DialogHeader,
  FormField,
  Button,
  Textarea,
} from "@tycholabs/armillary";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { UsePostNewPrivateNote } from "../../../../hooks/notes";

export interface AddPrivateNoteModalProps {
  setAddPrivateNoteModalOpen: Dispatch<SetStateAction<boolean>>;
  setToastVisible: Dispatch<SetStateAction<boolean>>;
  setToastText: Dispatch<SetStateAction<string>>;
  queryRefetch: any;
  lesson: string;
}

export interface AddPrivateNoteFormValues {
  noteText: string;
}

export const AddPrivateNoteModal = ({
  setAddPrivateNoteModalOpen,
  setToastVisible,
  setToastText,
  queryRefetch,
  lesson,
}: AddPrivateNoteModalProps) => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AddPrivateNoteFormValues>({
    defaultValues: {
      noteText: undefined,
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const postPrivateNote = UsePostNewPrivateNote(
    userId ?? "",
    businessId ?? "",
    lesson,
    { userId: `${userId}`, noteText: getValues("noteText") },
    session ?? ""
  );

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    postPrivateNote.mutateAsync().then((res) => {
      if (res.data === "Success") {
        queryRefetch();
        setAddPrivateNoteModalOpen(false);
        setToastText("Private Note added!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <DialogHeader title={"Add Private Note"} />
      <form
        noValidate
        onSubmit={handleSubmit(() => {
          try {
            handleSubmitButtonClick();
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <Flex direction="column" gap="30px" style={{ minWidth: "40vw" }}>
          <FormField
            label="Enter note"
            error={errors && errors.noteText?.message}
            limit={{
              value: watch(`noteText`) ? watch("noteText").length : 0,
              cap: 500,
            }}
          >
            <Textarea
              style={{ resize: "none" }}
              maxLength={500}
              placeholder="Enter your private note here."
              value={watch("noteText")}
              {...register(`noteText`, {
                required: "Please enter a note.",
                maxLength: {
                  value: 500,
                  message: "Note cannot be longer than 500 characters.",
                },
              })}
            />
          </FormField>
          <Button
            type={"primary"}
            width={"100px"}
            loading={isSubmitting}
            onClick={() => console.log("Submitted!")}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </>
  );
};

export default AddPrivateNoteModal;
