import {
  Flex,
  DialogHeader,
  TextInput,
  FormField,
  Group,
  Button,
  SingleSelect,
  SelectItem,
} from "@tycholabs/armillary";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { Event } from "../../../constants/Types";
import { UsePutUpdateEvent } from "../../../hooks/event";
import { statesList } from "../../../constants";

export interface EditEventModalProps {
  setEditEventModalOpen: Dispatch<SetStateAction<boolean>>;
  setToastVisible: Dispatch<SetStateAction<boolean>>;
  setToastText: Dispatch<SetStateAction<string>>;
  queryRefetch: any;
  event: Event | null;
}

export interface EditEventFormValues {
  name: string;
  startTime: string;
  startDate: string;
  regDeadline: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export const EditEventModal = ({
  setEditEventModalOpen,
  setToastVisible,
  setToastText,
  queryRefetch,
  event,
}: EditEventModalProps) => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<EditEventFormValues>({
    defaultValues: {
      name: event ? event.name : "",
      startTime: event ? event.startTime : "",
      startDate: event ? event.startDate.substring(0, 10) : "",
      regDeadline: event ? event.regDeadline.substring(0, 10) : "",
      address1: event ? event.address.address1 : "",
      address2: event ? event.address.address2 ?? undefined : "",
      city: event ? event.address.city : "",
      state: event ? event.address.state : "",
      zip: event ? event.address.zip : "",
      country: "USA",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const updateEvent = UsePutUpdateEvent(
    userId ?? "",
    businessId ?? "",
    {
      address: {
        id: event ? event.address.id : "",
        address1: getValues("address1"),
        address2: getValues("address2"),
        city: getValues("city"),
        state: getValues("state"),
        zip: getValues("zip"),
        country: getValues("country"),
      },
      event: {
        id: event ? event.id : "",
        name: getValues("name"),
        startTime: getValues("startTime"),
        startDate: getValues("startDate"),
        regDeadline: getValues("regDeadline"),
      },
    },
    session ?? ""
  );

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    updateEvent.mutateAsync().then((res) => {
      if (res.data === "Success") {
        queryRefetch();
        setEditEventModalOpen(false);
        setToastText("Event updated!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <DialogHeader title={"Update Event"} />
      <form
        noValidate
        onSubmit={handleSubmit(() => {
          try {
            handleSubmitButtonClick();
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <Flex direction="column" gap="30px">
          <Flex direction="row" gap="30px" style={{ minWidth: "50vw" }}>
            <Group label="Details" level="secondary">
              <Flex direction="column" gap="8px">
                <FormField
                  label="Name"
                  error={errors && errors.name?.message}
                  required
                >
                  <TextInput
                    required
                    value={watch(`name`)}
                    {...register("name", {
                      required: "Please enter an event name.",
                    })}
                  />
                </FormField>

                <FormField
                  label="Start time"
                  error={errors && errors.startTime?.message}
                  required
                >
                  <TextInput
                    type="time"
                    value={watch(`startTime`)}
                    {...register(`startTime`, {
                      required: "Please enter a valid start time.",
                    })}
                  />
                </FormField>

                <FormField
                  label="Start date"
                  error={errors && errors.startDate?.message}
                  required
                >
                  <TextInput
                    type="date"
                    value={watch(`startDate`)}
                    {...register(`startDate`, {
                      required: "Please enter a start date.",
                    })}
                  />
                </FormField>

                <FormField
                  label="Registration deadline"
                  error={errors && errors.regDeadline?.message}
                  required
                >
                  <TextInput
                    type="date"
                    value={watch(`regDeadline`)}
                    {...register(`regDeadline`, {
                      required: "Please enter a registration deadline.",
                    })}
                  />
                </FormField>
              </Flex>
            </Group>

            <Group label="Location" level="secondary">
              <Flex direction="column" gap="8px">
                <FormField
                  label="Address 1"
                  error={errors && errors.address1?.message}
                  required
                >
                  <TextInput
                    value={watch(`address1`)}
                    {...register(`address1`, {
                      required: "Please enter a valid address.",
                    })}
                  />
                </FormField>
                <FormField label="Address 2">
                  <TextInput
                    value={watch(`address2`)}
                    {...register(`address2`)}
                  />
                </FormField>
                <FormField
                  label="City"
                  error={errors && errors.city?.message}
                  required
                >
                  <TextInput
                    value={watch(`city`)}
                    {...register(`city`, {
                      required: "Please enter a city.",
                    })}
                  />
                </FormField>
                <FormField
                  label="State"
                  error={errors && errors.state?.message}
                  required
                >
                  <SingleSelect
                    placeholder="Select a state"
                    defaultValue={getValues("state")}
                    onValueChange={(val: string) => setValue(`state`, val)}
                    {...register(`state`, {
                      required: "Please select a state.",
                    })}
                  >
                    <Flex direction="column" style={{ maxHeight: "300px" }}>
                      {statesList.map((state) => {
                        return <SelectItem value={state}>{state}</SelectItem>;
                      })}
                    </Flex>
                  </SingleSelect>
                </FormField>
                <FormField
                  label="Zip"
                  error={errors && errors.zip?.message}
                  required
                >
                  <TextInput
                    value={watch(`zip`)}
                    {...register(`zip`, {
                      required: "Please enter a valid zip code.",
                    })}
                  />
                </FormField>
                <FormField
                  label="Country"
                  error={errors && errors.country?.message}
                  required
                >
                  <TextInput
                    disabled
                    value={watch(`country`)}
                    {...register(`country`, {
                      required: "Please select a country.",
                    })}
                  />
                </FormField>
              </Flex>
            </Group>
          </Flex>
          <Button
            type={"primary"}
            width={"100px"}
            loading={isSubmitting}
            onClick={() => console.log("Submitted!")}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </>
  );
};

export default EditEventModal;
