import { Flex, Heading, Card, DataOverlay } from "@tycholabs/armillary";

export const ProgressView = () => {
  return (
    <Flex style={{ maxWidth: "64vw", overflowY: "scroll" }}>
      <Card>
        <Flex direction="column" gap="0">
          <Heading>Progress</Heading>
          <Heading color="secondary" size="small" weight="semibold">
            View your student's curriculum progress.
          </Heading>
        </Flex>

        <DataOverlay exists={false}>
          <></>
        </DataOverlay>
      </Card>
    </Flex>
  );
};

export default ProgressView;
