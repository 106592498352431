import { MapPin, Music, Calendar } from "lucide-react";
import { Student } from "../../../constants/Types";
import {
  Button,
  Card,
  Flex,
  Heading,
  Text,
  Avatar,
  AvatarFallback,
  getUserColor,
  Separator,
} from "@tycholabs/armillary";
import { Link } from "react-router-dom";
import { getDaysList } from "../../../utils";
import { useTheme } from "styled-components";

export interface StudentCardProps {
  student: Student;
}

export const StudentCard = ({ student }: StudentCardProps) => {
  const theme = useTheme();
  return (
    <Card padding="20px" style={{ width: "280px" }}>
      <Flex direction="column" gap="16px">
        <Flex direction="row" gap="16px" align="center">
          <Avatar background={getUserColor(`${student.id}563hf`)} size="40px">
            <AvatarFallback>
              {`${
                student.prefName ? student.prefName[0] : student.firstName[0]
              }${student.lastName[0]}`}
            </AvatarFallback>
          </Avatar>
          <Heading size="small">
            {student.firstName} {student.middleName && student.middleName[0]}{" "}
            {student.lastName}
          </Heading>
        </Flex>

        <Flex direction="column" gap="8px">
          <Flex direction="row" gap="8px" align="center">
            <MapPin size={18} color={theme.colors.text.secondary} />
            <Text color="secondary">
              {student.lessons.length > 0 ? (
                <>{student.lessons[0].location.name}</>
              ) : (
                "-"
              )}
            </Text>
          </Flex>

          <Flex direction="row" gap="8px" align="center">
            <Music size={18} color={theme.colors.text.secondary} />
            <Text color="secondary">
              {student.lessons.length > 0 ? (
                <>
                  {student.lessons.map((lesson, i) => (
                    <>
                      {lesson.instrument}
                      {i === student.lessons.length - 1 ? "" : ", "}
                    </>
                  ))}
                </>
              ) : (
                "-"
              )}
            </Text>
          </Flex>

          <Flex direction="row" gap="8px" align="center">
            <Calendar size={18} color={theme.colors.text.secondary} />
            <Text color="secondary">
              {student.lessons.length > 0 ? (
                <>
                  {getDaysList(
                    student.lessons.map((lesson, i) => {
                      return lesson.days;
                    })
                  )}
                </>
              ) : (
                "-"
              )}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex direction="column" gap="16px" style={{ flexGrow: 1 }} justify="end">
        <Separator />

        <Flex direction="row" gap="8px" width="100%">
          <Link
            style={{ textDecoration: "none" }}
            to={`/students/student/${student.id}`}
          >
            <Button width="80px" type="secondary">
              View
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Card>
  );
};

export default StudentCard;
