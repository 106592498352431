import { MapPin } from "lucide-react";
import { Location } from "../../../constants/Types";
import { Flex, Heading, Text } from "@tycholabs/armillary";
import { useTheme } from "styled-components";

export interface LocationCardProps {
  location: Location;
}

export const LocationCard = ({ location }: LocationCardProps) => {
  const theme = useTheme();
  return (
    <Flex direction="column" gap="8px" align="left">
      <Heading
        size="medium"
        style={{
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {location.name}
      </Heading>

      <Flex direction="row" gap="8px" align="left">
        <MapPin size={18} color={theme.colors.text.secondary} />
        <Text
          size="small"
          color="secondary"
          style={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {`${location.address.address1}, ${
            location.address.address2 ? location.address.address2 + ", " : ""
          } ${location.address.city}, ${location.address.state}, ${
            location.address.zip
          }`}
        </Text>
      </Flex>
    </Flex>
  );
};

export default LocationCard;
