import { Contact, Student } from "../../../constants/Types";
import {
  Avatar,
  AvatarFallback,
  Button,
  Flex,
  getUserColor,
  Heading,
  Text,
  Separator,
  Card,
  CardGrid,
  Group,
  DataOverlay,
  Dialog,
  DialogTrigger,
  DialogContent,
  Toast,
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Checkbox,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuLabel,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "@tycholabs/armillary";
import {
  MapPin,
  Cake,
  Star,
  Trophy,
  Plus,
  MoreHorizontal,
  Trash,
} from "lucide-react";
import { UseContacts, UseStudent } from "../../../hooks/student";
import { useParams } from "react-router-dom";
import StudentLessonCard from "./StudentLessonCard";
import AddLessonModal from "../LessonManagement/AddLessonModal";
import { useState } from "react";
import AddContactModal from "./AddContactModal";
import DeleteContactModal from "./DeleteContactModal";
import EditStudentModal from "./EditStudentModal";
import { useTheme } from "styled-components";
import { convertDate } from "../../../utils";

export const StudentView = () => {
  const theme = useTheme();
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const permission = sessionStorage.getItem("permission");
  const session = sessionStorage.getItem("session");
  let params = useParams();
  const param = params.uid as string;
  const studentResponse = UseStudent(
    userId ?? "",
    businessId ?? "",
    param,
    session ?? ""
  );
  const studentData: Student = studentResponse?.data?.data[0];
  const contactsResponse = UseContacts(
    userId ?? "",
    businessId ?? "",
    param,
    session ?? ""
  );
  const contactsData: Contact[] = contactsResponse?.data?.data;

  const [editStudentModalOpen, setEditStudentModalOpen] = useState(false);
  const [addLessonModalOpen, setAddLessonModalOpen] = useState(false);
  const [addContactModalOpen, setAddContactModalOpen] = useState(false);
  const [deleteContactModalOpen, setDeleteContactModalOpen] = useState(false);
  const [contactToDelete, setContactToDelete] = useState<Contact | null>(null);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("Saved!");

  return (
    <Flex
      direction="row"
      gap="30px"
      style={{ maxWidth: "64vw", boxSizing: "border-box" }}
    >
      <DataOverlay
        loading={studentResponse.isLoading}
        error={studentResponse.isError}
        exists={studentData !== null}
      >
        {studentData && (
          <Flex direction="column" gap="16px">
            <Heading size="medium">
              View and edit {studentData.firstName}'s details.
            </Heading>

            <Card style={{ width: "100%" }}>
              <Flex direction="row" align="center" gap="30px">
                <Avatar
                  background={getUserColor(`${studentData.id}563hf`)}
                  size="80px"
                >
                  <AvatarFallback>
                    {`${studentData.firstName[0]}${studentData.lastName[0]}`}
                  </AvatarFallback>
                </Avatar>
                <Flex direction="column" align="left">
                  <Heading>{`${studentData.firstName} ${studentData.lastName}`}</Heading>
                </Flex>

                {permission === "1" && (
                  <Button
                    type="primary"
                    width="auto"
                    onClick={() => {
                      setEditStudentModalOpen(true);
                    }}
                  >
                    Edit
                  </Button>
                )}
              </Flex>

              <Group label="Details" level="secondary">
                <Flex direction="row" gap="8px" align="center">
                  <MapPin
                    height={16}
                    width={16}
                    color={theme.colors.text.primary}
                  />
                  <Text color="secondary">Address:</Text>
                  <Text color="primary">
                    {studentData.address.address1},{" "}
                    {studentData.address.address2
                      ? studentData.address.address2 + ", "
                      : ""}
                    {studentData.address.city}, {studentData.address.state},{" "}
                    {studentData.address.zip}
                  </Text>
                </Flex>
                <Flex direction="row" gap="8px" align="center">
                  <Cake
                    height={16}
                    width={16}
                    color={theme.colors.text.primary}
                  />
                  <Text color="secondary">Birthday:</Text>
                  <Text color="primary">
                    {convertDate(studentData.birthday)}
                  </Text>
                </Flex>
                <Flex direction="row" gap="8px" align="center">
                  <Star
                    height={16}
                    width={16}
                    color={theme.colors.text.primary}
                  />
                  <Text color="secondary">Start date:</Text>
                  <Text color="primary">
                    {convertDate(studentData.startDate)}
                  </Text>
                </Flex>
              </Group>
              <Separator />

              <Group label="Goals" level="secondary">
                <Flex direction="row" gap="8px" align="center">
                  <Trophy
                    height={16}
                    width={16}
                    color={theme.colors.text.primary}
                  />
                  <Text color="primary">{studentData.goals}</Text>
                </Flex>
              </Group>
            </Card>

            <Card style={{ width: "100%" }}>
              <Flex direction="column" gap="8px" width="800px">
                <Heading size="medium">Lessons</Heading>
                <CardGrid>
                  {studentData.lessons.map((lesson) => (
                    <StudentLessonCard lesson={lesson} />
                  ))}
                  {permission === "1" && (
                    <Dialog
                      open={addLessonModalOpen}
                      onOpenChange={setAddLessonModalOpen}
                    >
                      <DialogTrigger asChild>
                        <Button
                          type="outline"
                          height="100%"
                          padding={"30px"}
                          style={{
                            borderStyle: "dashed",
                            textDecoration: "none",
                            width: "24%",
                            minWidth: "250px",
                            maxWidth: "24%",
                            height: "180px",
                            borderRadius: "12px",
                            flexDirection: "row",
                            justifyContent: "left",
                          }}
                        >
                          <Flex
                            direction={"row"}
                            gap={"8px"}
                            align="center"
                            style={{ margin: "auto" }}
                          >
                            <Plus
                              width={"36px"}
                              height={"36px"}
                              color={theme.colors.text.secondary}
                            />
                            <Text size="xLarge" weight="bold" color="secondary">
                              Add lesson
                            </Text>
                          </Flex>
                        </Button>
                      </DialogTrigger>
                      <DialogContent
                        onPointerDownOutside={(e: any) => e.preventDefault()}
                      >
                        <AddLessonModal
                          setAddLessonModalOpen={setAddLessonModalOpen}
                          setToastVisible={setToastVisible}
                          setToastText={setToastText}
                          queryRefetch={studentResponse.refetch}
                          selectedStudentId={studentData.id}
                          selectedStudentName={`${studentData.firstName} ${studentData.lastName}`}
                        />
                      </DialogContent>
                    </Dialog>
                  )}
                </CardGrid>
              </Flex>
            </Card>

            <Card style={{ width: "100%" }}>
              <Flex direction="column" gap="8px">
                <Heading size="medium">Contacts</Heading>
                <DataOverlay
                  loading={contactsResponse.isLoading}
                  error={contactsResponse.isError}
                  exists={contactsData && contactsData.length > 0}
                >
                  {contactsData && contactsData.length > 0 && (
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>Name</TableHead>
                          <TableHead>Email</TableHead>
                          <TableHead>Phone</TableHead>
                          <TableHead>Primary Contact</TableHead>
                          <TableHead>Receiving emails</TableHead>
                          <TableHead>Notes</TableHead>
                          <TableHead></TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {contactsData.map((contact) => (
                          <TableRow key={contact.id}>
                            <TableCell>
                              {contact.firstName} {contact.lastName}
                            </TableCell>
                            <TableCell>{contact.email}</TableCell>
                            <TableCell>{contact.phone}</TableCell>
                            <TableCell>
                              <Checkbox disabled checked={contact.isPrimary} />
                            </TableCell>
                            <TableCell>
                              <Checkbox disabled checked={contact.emailOptIn} />
                            </TableCell>
                            <TableCell>{contact.notes}</TableCell>
                            <TableCell>
                              {permission === "1" && (
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild>
                                    <Button
                                      width="36px"
                                      padding="0px"
                                      type="ghost"
                                    >
                                      <MoreHorizontal
                                        width="24px"
                                        height="24px"
                                      />
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuPortal>
                                    <DropdownMenuContent>
                                      <DropdownMenuLabel>
                                        Contact options
                                      </DropdownMenuLabel>
                                      <DropdownMenuSeparator />
                                      <DropdownMenuItem
                                        onClick={() => {
                                          setContactToDelete(contact);
                                          setDeleteContactModalOpen(true);
                                        }}
                                      >
                                        <Trash width="16px" height="16px" />
                                        Delete
                                      </DropdownMenuItem>
                                    </DropdownMenuContent>
                                  </DropdownMenuPortal>
                                </DropdownMenu>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </DataOverlay>
                {permission === "1" && (
                  <Dialog
                    open={addContactModalOpen}
                    onOpenChange={setAddContactModalOpen}
                  >
                    <DialogTrigger asChild>
                      <Button
                        type="outline"
                        height="40px"
                        padding={"0px"}
                        style={{
                          borderStyle: "dashed",
                          borderWidth: 2,
                          textDecoration: "none",
                          borderRadius: "12px",
                          boxShadow: theme.colors.shadow.small,
                        }}
                      >
                        <Flex
                          direction={"row"}
                          gap={"8px"}
                          align="center"
                          justify="center"
                          style={{ margin: "auto" }}
                        >
                          <Plus size={30} color={theme.colors.text.secondary} />
                          <Text size="medium" weight="bold" color="secondary">
                            Add contact
                          </Text>
                        </Flex>
                      </Button>
                    </DialogTrigger>
                    <DialogContent
                      onPointerDownOutside={(e: any) => e.preventDefault()}
                    >
                      <AddContactModal
                        setAddContactModalOpen={setAddContactModalOpen}
                        setToastVisible={setToastVisible}
                        setToastText={setToastText}
                        queryRefetch={contactsResponse.refetch}
                        studentId={studentData.id}
                      />
                    </DialogContent>
                  </Dialog>
                )}
              </Flex>
            </Card>
          </Flex>
        )}
      </DataOverlay>
      <Toast title={toastText} open={toastVisible} />
      <Dialog
        open={editStudentModalOpen}
        onOpenChange={setEditStudentModalOpen}
      >
        <DialogContent onPointerDownOutside={(e: any) => e.preventDefault()}>
          <EditStudentModal
            setEditStudentModalOpen={setEditStudentModalOpen}
            setToastVisible={setToastVisible}
            setToastText={setToastText}
            queryRefetch={studentResponse.refetch}
            student={studentData}
          />
        </DialogContent>
      </Dialog>

      {studentData && (
        <Dialog
          open={deleteContactModalOpen}
          onOpenChange={setDeleteContactModalOpen}
        >
          <DialogContent onPointerDownOutside={(e: any) => e.preventDefault()}>
            <DeleteContactModal
              setDeleteContactModalOpen={setDeleteContactModalOpen}
              setToastVisible={setToastVisible}
              setToastText={setToastText}
              queryRefetch={contactsResponse.refetch}
              studentId={studentData.id}
              contact={contactToDelete}
            />
          </DialogContent>
        </Dialog>
      )}
    </Flex>
  );
};

export default StudentView;
