import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoint } from "../constants/Endpoints";

const GetMetadata = async () => {
  return await axios.get(`${endpoint}/metadata`).then((res) => {
    return res;
  });
};

export const UseMetadata = () => {
  const response = useQuery({
    queryKey: [`GetMetadata`],
    queryFn: () => GetMetadata(),
  });
  return response;
};
