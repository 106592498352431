import {
  Flex,
  DialogHeader,
  TextInput,
  FormField,
  Group,
  Button,
  SingleSelect,
  SelectItem,
} from "@tycholabs/armillary";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { UsePostNewLocation } from "../../../hooks/business";
import { statesList } from "../../../constants";

export interface AddLocationModalProps {
  setAddLocationModalOpen: Dispatch<SetStateAction<boolean>>;
  setToastVisible: Dispatch<SetStateAction<boolean>>;
  setToastText: Dispatch<SetStateAction<string>>;
  queryRefetch: any;
}

export interface AddLocationFormValues {
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export const AddLocationModal = ({
  setAddLocationModalOpen,
  setToastVisible,
  setToastText,
  queryRefetch,
}: AddLocationModalProps) => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AddLocationFormValues>({
    defaultValues: {
      name: undefined,
      address1: undefined,
      address2: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
      country: "USA",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const postLocation = UsePostNewLocation(
    userId ?? "",
    businessId ?? "",
    {
      address: {
        address1: getValues("address1"),
        address2: getValues("address2"),
        city: getValues("city"),
        state: getValues("state"),
        zip: getValues("zip"),
        country: getValues("country"),
      },
      location: {
        name: getValues("name"),
      },
    },
    session ?? ""
  );

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    postLocation.mutateAsync().then((res) => {
      if (res.data === "Success") {
        queryRefetch();
        setAddLocationModalOpen(false);
        setToastText("Location added!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <DialogHeader title={"Add Location"} />
      <form
        noValidate
        onSubmit={handleSubmit(() => {
          try {
            handleSubmitButtonClick();
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <Flex direction="column" gap="30px" style={{ minWidth: "50vw" }}>
          <Group label="Details" level="secondary">
            <Flex direction="column" gap="8px">
              <FormField
                label="Name"
                error={errors && errors.name?.message}
                required
              >
                <TextInput
                  required
                  value={watch(`name`)}
                  {...register("name", {
                    required: "Please enter a location name.",
                  })}
                />
              </FormField>
            </Flex>
          </Group>

          <Group label="Location" level="secondary">
            <Flex direction="column" gap="8px">
              <FormField
                label="Address 1"
                error={errors && errors.address1?.message}
                required
              >
                <TextInput
                  value={watch(`address1`)}
                  {...register(`address1`, {
                    required: "Please enter a valid address.",
                  })}
                />
              </FormField>
              <FormField label="Address 2">
                <TextInput
                  value={watch(`address2`)}
                  {...register(`address2`)}
                />
              </FormField>
              <FormField
                label="City"
                error={errors && errors.city?.message}
                required
              >
                <TextInput
                  value={watch(`city`)}
                  {...register(`city`, {
                    required: "Please enter a city.",
                  })}
                />
              </FormField>
              <FormField
                label="State"
                error={errors && errors.state?.message}
                required
              >
                <SingleSelect
                  placeholder="Select a state"
                  defaultValue={getValues("state")}
                  onValueChange={(val: string) => setValue(`state`, val)}
                  {...register(`state`, {
                    required: "Please select a state.",
                  })}
                >
                  <Flex direction="column" style={{ maxHeight: "300px" }}>
                    {statesList.map((state) => {
                      return <SelectItem value={state}>{state}</SelectItem>;
                    })}
                  </Flex>
                </SingleSelect>
              </FormField>
              <FormField
                label="Zip"
                error={errors && errors.zip?.message}
                required
              >
                <TextInput
                  value={watch(`zip`)}
                  {...register(`zip`, {
                    required: "Please enter a valid zip code.",
                  })}
                />
              </FormField>
              <FormField
                label="Country"
                error={errors && errors.country?.message}
                required
              >
                <TextInput
                  disabled
                  value={watch(`country`)}
                  {...register(`country`, {
                    required: "Please select a country.",
                  })}
                />
              </FormField>
            </Flex>
          </Group>
          <Button
            type={"primary"}
            width={"100px"}
            loading={isSubmitting}
            onClick={() => console.log("Submitted!")}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </>
  );
};

export default AddLocationModal;
