import {
  Button,
  DataTableColumnHeader,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuItem,
  Flex,
} from "@tycholabs/armillary";
import { MoreHorizontal, Trash } from "lucide-react";
import { Link } from "react-router-dom";
import { convertTo12Hr, getDaysList } from "../../../utils";
import { Dispatch, SetStateAction } from "react";
import { Lesson } from "../../../constants/Types";

const FilterDaysFn = (row: any, key: string, searchValue: string) => {
  // return !!row?.original[key].find((item: string) =>
  //   item.includes(searchValue)
  // );

  //This one works for arrays of days
  // const set1 = new Set(searchValue);
  // const commonItems = row?.original[key].filter((item: string) =>
  //   set1.has(item)
  // );
  // return commonItems.length > 0;

  //This one works for a days string
  const set1 = new Set(searchValue);
  const commonItems = row?.original[key]
    .split("")
    .filter((item: string) => set1.has(item));
  return commonItems.length > 0;
};

export const getLessonsColumns = (
  setLessonToDelete: Dispatch<SetStateAction<Lesson | null>>,
  setDeleteLessonModalOpen: Dispatch<SetStateAction<boolean>>,
  permission: string | null
) => {
  return [
    {
      id: "teacher.name",
      accessorKey: "teacher.name",
      meta: "Teacher",
      filterFn: (
        row: { getValue: (arg0: any) => any },
        id: any,
        value: string | any[]
      ) => {
        return value.includes(row.getValue(id));
      },
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Teacher" />
      ),
    },
    {
      id: "student.name",
      accessorKey: "student.name",
      meta: "Student",
      filterFn: (
        row: { getValue: (arg0: any) => any },
        id: any,
        value: string | any[]
      ) => {
        return value.includes(row.getValue(id));
      },
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Student" />
      ),
    },
    {
      id: "location.name",
      accessorKey: "location.name",
      meta: "Location",
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Location" />
      ),
    },
    {
      id: "instrument",
      accessorKey: "instrument",
      meta: "Instrument",
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Instrument" />
      ),
    },
    // {
    //   id: "length",
    //   accessorKey: "length",
    //   header: ({ column }: any) => (
    //     <DataTableColumnHeader column={column} title="Length" />
    //   ),
    //   cell: ({ row }: any) => {
    //     if (row.original.length) {
    //       return row.original.length;
    //     } else {
    //       return "-";
    //     }
    //   },
    // },
    {
      id: "days",
      accessorKey: "days",
      meta: "Days",
      filterFn: FilterDaysFn,
      // filterFn: (
      //   row: { getValue: (arg0: any) => any },
      //   id: any,
      //   value: string | any[]
      // ) => {
      //   return value.includes(row.getValue(id));
      // },
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Days" />
      ),
      cell: ({ row }: any) => {
        if (row.original.days) {
          return getDaysList(row.original.days.split(""), true);
        } else {
          return "-";
        }
      },
    },
    {
      id: "time",
      accessorKey: "time",
      meta: "Time",
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Time" />
      ),
      cell: ({ row }: any) => {
        if (row.original.time) {
          return convertTo12Hr(row.original.time);
        } else {
          return "-";
        }
      },
    },
    {
      id: "actions",
      meta: "Actions",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Options" justify="left" />
      ),
      cell: ({ row }: any) => {
        return (
          <Flex direction="row" gap="4px">
            <Link
              style={{ textDecoration: "none" }}
              to={`/students/lesson/${row.original.id}`}
            >
              <Button type="outline" width="auto">
                View
              </Button>
            </Link>
            {permission === "1" && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button width="36px" padding="0px" type="ghost">
                    <MoreHorizontal width="24px" height="24px" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuContent>
                    <DropdownMenuLabel>Lesson options</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    {/* <Link
                    style={{ textDecoration: "none" }}
                    to={`/lessons/${row.original.id}`}
                  >
                    <DropdownMenuItem>
                      <Eye width="16px" height="16px" />
                      View
                    </DropdownMenuItem>
                  </Link> */}
                    {permission === "1" && (
                      <>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem
                          onClick={() => {
                            setLessonToDelete(row.original);
                            setDeleteLessonModalOpen(true);
                          }}
                        >
                          <Trash width="16px" height="16px" />
                          Delete
                        </DropdownMenuItem>
                      </>
                    )}
                  </DropdownMenuContent>
                </DropdownMenuPortal>
              </DropdownMenu>
            )}
          </Flex>
        );
      },
    },
  ];
};
