import {
  Card,
  CardGrid,
  DataTable,
  DataOverlay,
  Flex,
  Heading,
  Dialog,
  DialogTrigger,
  Button,
  DialogContent,
  Toast,
  ToggleGroup,
  ToggleGroupItem,
  Separator,
} from "@tycholabs/armillary";
import { UseStudentsList } from "../../../hooks/student";
import { Student } from "../../../constants/Types";
import { getStudentsColumns } from "./StudentsColumns";
import StudentCard from "./StudentCard";
import { useState } from "react";
import AddStudentModal from "./AddStudentModal";
import { SheetIcon, TablePropertiesIcon } from "lucide-react";
import DeleteStudentModal from "./DeleteStudentModal";

export const StudentManagement = () => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const permission = sessionStorage.getItem("permission");
  const session = sessionStorage.getItem("session");
  const studentResponse = UseStudentsList(
    userId ?? "",
    businessId ?? "",
    session ?? ""
  );
  const studentsData: Student[] = studentResponse?.data?.data;

  const [selectedDataView, setSelectedDataView] = useState<string>("table");

  const [addStudentModalOpen, setAddStudentModalOpen] = useState(false);
  const [deleteStudentModalOpen, setDeleteStudentModalOpen] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState<Student | null>(null);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("Saved!");

  return (
    <Flex
      direction="column"
      gap="20px"
      style={{ maxWidth: "82vw", boxSizing: "border-box" }}
    >
      <DataOverlay
        loading={studentResponse.isLoading}
        error={studentResponse.isError}
        exists={studentsData && studentsData.length > 0}
      >
        <Flex direction="row">
          <Heading size="medium">Students</Heading>
          <Flex direction="row" align="end" justify="right" gap="8px">
            {permission === "1" && (
              <>
                <Dialog
                  open={addStudentModalOpen}
                  onOpenChange={setAddStudentModalOpen}
                >
                  <DialogTrigger asChild>
                    <Button type="primary" width="140px">
                      Add student
                    </Button>
                  </DialogTrigger>
                  <DialogContent
                    onPointerDownOutside={(e: any) => e.preventDefault()}
                  >
                    <AddStudentModal
                      setAddStudentModalOpen={setAddStudentModalOpen}
                      setToastVisible={setToastVisible}
                      setToastText={setToastText}
                      queryRefetch={studentResponse.refetch}
                    />
                  </DialogContent>
                </Dialog>
                <Separator orientation="vertical" />
              </>
            )}
            <ToggleGroup
              value={selectedDataView}
              onValueChange={(val: string) => {
                if (val.length > 0) {
                  setSelectedDataView(val);
                }
              }}
              type="single"
            >
              <ToggleGroupItem value="table">
                <TablePropertiesIcon />
              </ToggleGroupItem>
              <ToggleGroupItem value="card">
                <SheetIcon />
              </ToggleGroupItem>
            </ToggleGroup>
          </Flex>
        </Flex>

        <Card style={{ width: "100%" }}>
          {studentsData && (
            <>
              {selectedDataView === "table" ? (
                <DataTable
                  searchFilters={[
                    {
                      value: "firstName",
                      placeholder: "Search first name",
                      width: "200px",
                    },
                    {
                      value: "lastName",
                      placeholder: "Search last name",
                      width: "200px",
                    },
                  ]}
                  pagination
                  columns={getStudentsColumns(
                    setStudentToDelete,
                    setDeleteStudentModalOpen,
                    permission
                  )}
                  columnSelection
                  data={studentsData}
                />
              ) : (
                <CardGrid>
                  {studentsData.map((student) => (
                    <StudentCard student={student} />
                  ))}
                </CardGrid>
              )}
            </>
          )}
        </Card>
      </DataOverlay>
      <Toast title={toastText} open={toastVisible} />
      <Dialog
        open={deleteStudentModalOpen}
        onOpenChange={setDeleteStudentModalOpen}
      >
        <DialogContent onPointerDownOutside={(e: any) => e.preventDefault()}>
          <DeleteStudentModal
            setDeleteStudentModalOpen={setDeleteStudentModalOpen}
            setToastVisible={setToastVisible}
            setToastText={setToastText}
            queryRefetch={studentResponse.refetch}
            student={studentToDelete}
          />
        </DialogContent>
      </Dialog>
    </Flex>
  );
};

export default StudentManagement;
