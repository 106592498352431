import {
  Flex,
  DialogHeader,
  SingleSelect,
  SelectItem,
  TextInput,
  FormField,
  Group,
  Button,
} from "@tycholabs/armillary";
import { Student } from "../../../constants/Types";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { UsePutUpdateStudent } from "../../../hooks/student";
import { statesList } from "../../../constants";

export interface EditStudentModalProps {
  setEditStudentModalOpen: Dispatch<SetStateAction<boolean>>;
  setToastVisible: Dispatch<SetStateAction<boolean>>;
  setToastText: Dispatch<SetStateAction<string>>;
  queryRefetch: any;
  student: Student;
}

export interface EditStudentFormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  prefName: string;
  birthday: string;
  goals: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export const EditStudentModal = ({
  setEditStudentModalOpen,
  setToastVisible,
  setToastText,
  queryRefetch,
  student,
}: EditStudentModalProps) => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<EditStudentFormValues>({
    defaultValues: {
      firstName: student.firstName ?? undefined,
      middleName: student.middleName ?? undefined,
      lastName: student.lastName ?? undefined,
      prefName: student.prefName ?? undefined,
      birthday: student.birthday.substring(0, 10) ?? undefined,
      goals: student.goals ?? undefined,
      address1: student.address.address1 ?? undefined,
      address2: student.address.address2 ?? undefined,
      city: student.address.city ?? undefined,
      state: student.address.state ?? undefined,
      zip: student.address.zip ?? undefined,
      country: "USA",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const updateStudent = UsePutUpdateStudent(
    userId ?? "",
    businessId ?? "",
    {
      student: {
        id: student ? student.id : "",
        firstName: getValues("firstName"),
        middleName: getValues("middleName"),
        lastName: getValues("lastName"),
        prefName: getValues("prefName"),
        birthday: getValues("birthday"),
        goals: getValues("goals"),
      },
      address: {
        id: student ? student.address.id : "",
        address1: getValues("address1"),
        address2: getValues("address2"),
        city: getValues("city"),
        state: getValues("state"),
        zip: getValues("zip"),
        country: getValues("country"),
      },
    },
    session ?? ""
  );

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    updateStudent.mutateAsync().then((res) => {
      if (res.data === "Success") {
        queryRefetch();
        setEditStudentModalOpen(false);
        setToastText("Student updated!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <DialogHeader
        title={`Edit ${student.firstName} ${student.lastName}'s personal details`}
      />
      <form
        noValidate
        onSubmit={handleSubmit(() => {
          try {
            handleSubmitButtonClick();
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <Flex direction="column" gap="30px">
          <Flex direction="row" gap="30px" width="60vw">
            <Group label="Student details" level="secondary">
              <Flex direction="column" gap="8px">
                <FormField
                  label="First name"
                  error={errors && errors.firstName?.message}
                  required
                >
                  <TextInput
                    value={watch(`firstName`)}
                    {...register(`firstName`, {
                      required: "Please enter a first name.",
                    })}
                  />
                </FormField>

                <FormField label="Middle name">
                  <TextInput
                    value={watch(`middleName`)}
                    {...register(`middleName`)}
                  />
                </FormField>

                <FormField
                  label="Last name"
                  error={errors && errors.lastName?.message}
                  required
                >
                  <TextInput
                    value={watch(`lastName`)}
                    {...register(`lastName`, {
                      required: "Please enter a last name.",
                    })}
                  />
                </FormField>

                <FormField label="Preferred name">
                  <TextInput
                    value={watch(`prefName`)}
                    {...register(`prefName`)}
                  />
                </FormField>

                <FormField
                  label="Birthday"
                  error={errors && errors.birthday?.message}
                  required
                >
                  <TextInput
                    type="date"
                    value={watch("birthday")}
                    {...register(`birthday`, {
                      required: "Please select a birthday.",
                    })}
                  />
                </FormField>

                <FormField
                  label="Goals"
                  error={errors && errors.goals?.message}
                  required
                >
                  <TextInput
                    value={watch(`goals`)}
                    {...register(`goals`, {
                      required: "Please enter a goal.",
                    })}
                  />
                </FormField>
              </Flex>
            </Group>
            <Group label="Address information" level="secondary">
              <Flex direction="column" gap="8px">
                <FormField
                  label="Address 1"
                  error={errors && errors.address1?.message}
                  required
                >
                  <TextInput
                    value={watch(`address1`)}
                    {...register(`address1`, {
                      required: "Please enter a valid address.",
                    })}
                  />
                </FormField>

                <FormField label="Address 2">
                  <TextInput
                    value={watch(`address2`)}
                    {...register(`address2`)}
                  />
                </FormField>

                <FormField
                  label="City"
                  error={errors && errors.city?.message}
                  required
                >
                  <TextInput
                    value={watch(`city`)}
                    {...register(`city`, {
                      required: "Please enter a city.",
                    })}
                  />
                </FormField>

                <FormField
                  label="State"
                  error={errors && errors.state?.message}
                  required
                >
                  <SingleSelect
                    placeholder="Select a state"
                    defaultValue={getValues("state")}
                    onValueChange={(val: string) => setValue(`state`, val)}
                    {...register(`state`, {
                      required: "Please select a state.",
                    })}
                  >
                    <Flex direction="column" style={{ maxHeight: "300px" }}>
                      {statesList.map((state) => {
                        return <SelectItem value={state}>{state}</SelectItem>;
                      })}
                    </Flex>
                  </SingleSelect>
                </FormField>

                <FormField
                  label="Zip"
                  error={errors && errors.zip?.message}
                  required
                >
                  <TextInput
                    value={watch(`zip`)}
                    {...register(`zip`, {
                      required: "Please enter a valid zip code.",
                    })}
                  />
                </FormField>

                <FormField
                  label="Country"
                  error={errors && errors.country?.message}
                  required
                >
                  <TextInput
                    disabled
                    value={watch(`country`)}
                    {...register(`country`, {
                      required: "Please select a country.",
                    })}
                  />
                </FormField>
              </Flex>
            </Group>
          </Flex>
          <Button
            type={"primary"}
            width={"100px"}
            loading={isSubmitting}
            onClick={() => console.log("Submitted!")}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </>
  );
};

export default EditStudentModal;
