import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoint } from "../constants/Endpoints";
import { DeleteUserDTO } from "../constants/DTO";

const GetUsersList = async (
  userId: string,
  businessId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/users`, {
      params: { user: userId, business: businessId, session: sessionId },
    })
    .then((res) => {
      return res;
    });
};

export const UseUsersList = (
  userId: string,
  businessId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-GetUsersList`],
    queryFn: () => GetUsersList(userId, businessId, sessionId),
  });
  return response;
};

const GetUser = async (
  userId: string,
  businessId: string,
  teacherId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/users`, {
      params: {
        user: userId,
        business: businessId,
        teacher: teacherId,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseUser = (
  userId: string,
  businessId: string,
  teacherId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-${teacherId}-GetUsersList`],
    queryFn: () => GetUser(userId, businessId, teacherId, sessionId),
  });
  return response;
};

const DeleteUser = async (
  userId: string,
  businessId: string,
  user: DeleteUserDTO,
  sessionId: string
) => {
  return await axios
    .delete(`${endpoint}/users`, {
      data: {
        user: userId,
        business: businessId,
        userDetails: user,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseDeleteUser = (
  userId: string,
  businessId: string,
  user: DeleteUserDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-DeleteUser`],
    mutationFn: () => DeleteUser(userId, businessId, user, sessionId),
  });
  return response;
};

const GetUserLessons = async (
  userId: string,
  businessId: string,
  teacherId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/lessons`, {
      params: {
        user: userId,
        business: businessId,
        teacher: teacherId,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseUserLessons = (
  userId: string,
  businessId: string,
  teacherId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-${teacherId}-GetUserLessons`],
    queryFn: () => GetUserLessons(userId, businessId, teacherId, sessionId),
  });
  return response;
};
