import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoint } from "../constants/Endpoints";
import { AddLocationDTO, UpdateLocationDTO } from "../constants/DTO";

const GetBusinessInfo = async (
  userId: string,
  businessId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/business`, {
      params: { user: userId, business: businessId, session: sessionId },
    })
    .then((res) => {
      return res;
    });
};

export const UseBusinessInfo = (
  userId: string,
  businessId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-GetBusinessInfo`],
    queryFn: () => GetBusinessInfo(userId, businessId, sessionId),
  });
  return response;
};

const PostNewLocation = async (
  userId: string,
  businessId: string,
  location: AddLocationDTO,
  sessionId: string
) => {
  return await axios
    .post(
      `${endpoint}/business`,
      {
        address: location.address,
        location: location.location,
      },
      {
        params: {
          user: userId,
          business: businessId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePostNewLocation = (
  userId: string,
  businessId: string,
  location: AddLocationDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PostNewLocation`],
    mutationFn: () => PostNewLocation(userId, businessId, location, sessionId),
  });
  return response;
};

const PutUpdateLocation = async (
  userId: string,
  businessId: string,
  location: UpdateLocationDTO,
  sessionId: string
) => {
  return await axios
    .put(
      `${endpoint}/business`,
      {
        address: location.address,
        location: location.location,
      },
      {
        params: {
          user: userId,
          business: businessId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePutUpdateLocation = (
  userId: string,
  businessId: string,
  location: UpdateLocationDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PutUpdateLocation`],
    mutationFn: () =>
      PutUpdateLocation(userId, businessId, location, sessionId),
  });
  return response;
};
