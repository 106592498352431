import { Flex, DialogHeader, Button, Text } from "@tycholabs/armillary";
import { Dispatch, SetStateAction, useState } from "react";
import { UseDeletePiece } from "../../../../hooks/piece";
import { Piece } from "../../../../constants/Types";

export interface DeletePieceModalProps {
  setDeletePieceModalOpen: Dispatch<SetStateAction<boolean>>;
  setToastVisible: Dispatch<SetStateAction<boolean>>;
  setToastText: Dispatch<SetStateAction<string>>;
  queryRefetch: any;
  lessonId: string;
  piece: Piece | null;
}

export const DeletePieceModal = ({
  setDeletePieceModalOpen,
  setToastVisible,
  setToastText,
  queryRefetch,
  lessonId,
  piece,
}: DeletePieceModalProps) => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const deletePiece = UseDeletePiece(
    userId ?? "",
    businessId ?? "",
    {
      id: piece ? piece.id : "",
      lessonId: lessonId ?? "",
    },
    session ?? ""
  );

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    deletePiece.mutateAsync().then((res) => {
      if (res.data === "Success") {
        queryRefetch();
        setDeletePieceModalOpen(false);
        setToastText("Piece removed!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <DialogHeader title={"Remove Piece?"} />
      <Text>This action cannot be undone.</Text>
      <Flex direction="row" gap="30px">
        <Button
          type={"secondary"}
          width={"100px"}
          onClick={() => setDeletePieceModalOpen(false)}
        >
          Cancel
        </Button>
        <Button
          type={"destructive"}
          width={"100px"}
          loading={isSubmitting}
          onClick={() => handleSubmitButtonClick()}
        >
          Delete
        </Button>
      </Flex>
    </>
  );
};

export default DeletePieceModal;
