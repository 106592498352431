import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoint } from "../constants/Endpoints";
import {
  AddLessonDTO,
  DeleteLessonDTO,
  PostSendEmailDTO,
  UpdateLessonDTO,
} from "../constants/DTO";

const GetLessonsList = async (
  userId: string,
  businessId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/lessons`, {
      params: { user: userId, business: businessId, session: sessionId },
    })
    .then((res) => {
      return res;
    });
};

export const UseLessonsList = (
  userId: string,
  businessId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-GetLessonsList`],
    queryFn: () => GetLessonsList(userId, businessId, sessionId),
  });
  return response;
};

const GetLesson = async (
  userId: string,
  businessId: string,
  lessonId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/lessons`, {
      params: {
        user: userId,
        business: businessId,
        lesson: lessonId,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseLesson = (
  userId: string,
  businessId: string,
  lessonId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-${lessonId}-GetLesson`],
    queryFn: () => GetLesson(userId, businessId, lessonId, sessionId),
  });
  return response;
};

const PostNewLesson = async (
  userId: string,
  businessId: string,
  lesson: AddLessonDTO,
  sessionId: string
) => {
  return await axios
    .post(
      `${endpoint}/lessons`,
      {
        lesson,
      },
      {
        params: {
          user: userId,
          business: businessId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePostNewLesson = (
  userId: string,
  businessId: string,
  lesson: AddLessonDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PostNewLesson`],
    mutationFn: () => PostNewLesson(userId, businessId, lesson, sessionId),
  });
  return response;
};

const PutUpdateLesson = async (
  userId: string,
  businessId: string,
  lesson: UpdateLessonDTO,
  sessionId: string
) => {
  return await axios
    .put(
      `${endpoint}/lessons`,
      {
        lesson: lesson,
      },
      {
        params: {
          user: userId,
          business: businessId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePutUpdateLesson = (
  userId: string,
  businessId: string,
  lesson: UpdateLessonDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PutUpdateLesson`],
    mutationFn: () => PutUpdateLesson(userId, businessId, lesson, sessionId),
  });
  return response;
};

const DeleteLesson = async (
  userId: string,
  businessId: string,
  lesson: DeleteLessonDTO,
  sessionId: string
) => {
  return await axios
    .delete(`${endpoint}/lessons`, {
      data: {
        user: userId,
        business: businessId,
        lesson: lesson,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseDeleteLesson = (
  userId: string,
  businessId: string,
  lesson: DeleteLessonDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-DeleteLesson`],
    mutationFn: () => DeleteLesson(userId, businessId, lesson, sessionId),
  });
  return response;
};

const GetLessonSentToday = async (
  userId: string,
  businessId: string,
  lessonId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/lessons/lastsent`, {
      params: {
        user: userId,
        business: businessId,
        lesson: lessonId,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseLessonSentToday = (
  userId: string,
  businessId: string,
  lessonId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-${lessonId}-GetLessonSentToday`],
    queryFn: () => GetLessonSentToday(userId, businessId, lessonId, sessionId),
  });
  return response;
};

const PostSendEmail = async (
  userId: string,
  businessId: string,
  email: PostSendEmailDTO,
  sessionId: string
) => {
  return await axios
    .post(
      `${endpoint}/lessons/send`,
      {
        email,
      },
      {
        params: {
          user: userId,
          business: businessId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePostSendEmail = (
  userId: string,
  businessId: string,
  email: PostSendEmailDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PostSendEmail`],
    mutationFn: () => PostSendEmail(userId, businessId, email, sessionId),
  });
  return response;
};
