import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoint } from "../constants/Endpoints";
import {
  AddPrivateNoteDTO,
  DeletePrivateNoteDTO,
  UpdateParentNoteDTO,
} from "../constants/DTO";

const GetParentNote = async (
  userId: string,
  businessId: string,
  lessonId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/notes/parent`, {
      params: {
        user: userId,
        business: businessId,
        lesson: lessonId,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseParentNote = (
  userId: string,
  businessId: string,
  lessonId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-${lessonId}-GetParentNote`],
    queryFn: () => GetParentNote(userId, businessId, lessonId, sessionId),
  });
  return response;
};

const PutUpdateParentNote = async (
  userId: string,
  businessId: string,
  lessonId: string,
  note: UpdateParentNoteDTO,
  sessionId: string
) => {
  return await axios
    .put(
      `${endpoint}/notes/parent`,
      {
        note: note,
      },
      {
        params: {
          user: userId,
          business: businessId,
          lesson: lessonId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePutUpdateParentNote = (
  userId: string,
  businessId: string,
  lessonId: string,
  note: UpdateParentNoteDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PutUpdateParentNote`],
    mutationFn: () =>
      PutUpdateParentNote(userId, businessId, lessonId, note, sessionId),
  });
  return response;
};

const GetPrivateNotesList = async (
  userId: string,
  businessId: string,
  lessonId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/notes/private`, {
      params: {
        user: userId,
        business: businessId,
        lesson: lessonId,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UsePrivateNotesList = (
  userId: string,
  businessId: string,
  lessonId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-${lessonId}-GetPrivateNotes`],
    queryFn: () => GetPrivateNotesList(userId, businessId, lessonId, sessionId),
  });
  return response;
};

const PostNewPrivateNote = async (
  userId: string,
  businessId: string,
  lessonId: string,
  note: AddPrivateNoteDTO,
  sessionId: string
) => {
  return await axios
    .post(
      `${endpoint}/notes/private`,
      {
        note: note,
      },
      {
        params: {
          user: userId,
          business: businessId,
          lesson: lessonId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePostNewPrivateNote = (
  userId: string,
  businessId: string,
  lessonId: string,
  note: AddPrivateNoteDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PostNewPrivateNote`],
    mutationFn: () =>
      PostNewPrivateNote(userId, businessId, lessonId, note, sessionId),
  });
  return response;
};

const DeletePrivateNote = async (
  userId: string,
  businessId: string,
  lessonId: string,
  note: DeletePrivateNoteDTO,
  sessionId: string
) => {
  return await axios
    .delete(`${endpoint}/notes/private`, {
      data: {
        user: userId,
        business: businessId,
        lesson: lessonId,
        note: note,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseDeletePrivateNote = (
  userId: string,
  businessId: string,
  lessonId: string,
  note: DeletePrivateNoteDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-DeletePrivateNote`],
    mutationFn: () =>
      DeletePrivateNote(userId, businessId, lessonId, note, sessionId),
  });
  return response;
};
