import { Flex, Heading, Card, Button, Text } from "@tycholabs/armillary";
import { useNavigate } from "react-router-dom";

export const ResetPasswordSuccess = () => {
  const navigate = useNavigate();

  return (
    <Flex direction="row" justify="center" align="center">
      <Card style={{ width: "50%", minWidth: "400px" }}>
        <Heading>Reset Password</Heading>
        <Heading>Success!</Heading>
        <Text size="small" color="secondary">
          Click below to sign in with your new password.
        </Text>
        <Flex direction="column" gap="8px" align="center">
          <Button
            width="auto"
            type="primary"
            onClick={() => navigate("/login")}
          >
            Sign In
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
};

export default ResetPasswordSuccess;
