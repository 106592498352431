import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoint } from "../constants/Endpoints";
import { AddEventDTO, DeleteEventDTO, UpdateEventDTO } from "../constants/DTO";

const GetEventsList = async (
  userId: string,
  businessId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/events`, {
      params: { user: userId, business: businessId, session: sessionId },
    })
    .then((res) => {
      return res;
    });
};

export const UseEventsList = (
  userId: string,
  businessId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-GetEventsList`],
    queryFn: () => GetEventsList(userId, businessId, sessionId),
  });
  return response;
};

const PostNewEvent = async (
  userId: string,
  businessId: string,
  event: AddEventDTO,
  sessionId: string
) => {
  return await axios
    .post(
      `${endpoint}/events`,
      {
        address: event.address,
        event: event.event,
      },
      {
        params: {
          user: userId,
          business: businessId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePostNewEvent = (
  userId: string,
  businessId: string,
  event: AddEventDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PostNewEvent`],
    mutationFn: () => PostNewEvent(userId, businessId, event, sessionId),
  });
  return response;
};

const PutUpdateEvent = async (
  userId: string,
  businessId: string,
  event: UpdateEventDTO,
  sessionId: string
) => {
  return await axios
    .put(
      `${endpoint}/events`,
      {
        address: event.address,
        event: event.event,
      },
      {
        params: {
          user: userId,
          business: businessId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePutUpdateEvent = (
  userId: string,
  businessId: string,
  event: UpdateEventDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PutUpdateEvent`],
    mutationFn: () => PutUpdateEvent(userId, businessId, event, sessionId),
  });
  return response;
};

const DeleteEvent = async (
  userId: string,
  businessId: string,
  event: DeleteEventDTO,
  sessionId: string
) => {
  return await axios
    .delete(`${endpoint}/events`, {
      data: {
        user: userId,
        business: businessId,
        event: event,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseDeleteEvent = (
  userId: string,
  businessId: string,
  event: DeleteEventDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-DeleteEvent`],
    mutationFn: () => DeleteEvent(userId, businessId, event, sessionId),
  });
  return response;
};
