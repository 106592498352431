import {
  Flex,
  DialogHeader,
  TextInput,
  FormField,
  Group,
  Button,
  SingleSelect,
  SelectItem,
  MultiStep,
  MultiStepCard,
} from "@tycholabs/armillary";
import { Dispatch, SetStateAction, useState } from "react";
import { emailRegex, statesList } from "../../../constants";
import { useForm } from "react-hook-form";
import { UsePostNewStudent } from "../../../hooks/student";

export interface AddStudentModalProps {
  setAddStudentModalOpen: Dispatch<SetStateAction<boolean>>;
  setToastVisible: Dispatch<SetStateAction<boolean>>;
  setToastText: Dispatch<SetStateAction<string>>;
  queryRefetch: any;
}

export interface AddStudentFormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  prefName: string;
  birthday: string;
  startDate: string;
  contactNotes: string;
  goals: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  conEmail: string;
  conPhone: string;
  conFirstName: string;
  conLastName: string;
  conIsPrimary: boolean;
  conEmailOptIn: boolean;
  conNotes: string;
}

export const AddStudentModal = ({
  setAddStudentModalOpen,
  setToastVisible,
  setToastText,
  queryRefetch,
}: AddStudentModalProps) => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");

  const [activeStep, setActiveStep] = useState(0);

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AddStudentFormValues>({
    defaultValues: {
      firstName: undefined,
      middleName: undefined,
      lastName: undefined,
      prefName: undefined,
      birthday: undefined,
      startDate: undefined,
      goals: undefined,
      contactNotes: undefined,
      address1: undefined,
      address2: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
      country: "USA",
      conEmail: undefined,
      conPhone: undefined,
      conFirstName: undefined,
      conLastName: undefined,
      conIsPrimary: true,
      conEmailOptIn: true,
      conNotes: undefined,
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const postStudent = UsePostNewStudent(
    userId ?? "",
    businessId ?? "",
    {
      student: {
        firstName: getValues("firstName"),
        middleName: getValues("middleName"),
        lastName: getValues("lastName"),
        prefName: getValues("prefName"),
        birthday: getValues("birthday"),
        startDate: getValues("startDate"),
        goals: getValues("goals"),
      },
      address: {
        address1: getValues("address1"),
        address2: getValues("address2"),
        city: getValues("city"),
        state: getValues("state"),
        zip: getValues("zip"),
        country: getValues("country"),
      },
      contact: {
        email: getValues("conEmail"),
        phone: getValues("conPhone"),
        firstName: getValues("conFirstName"),
        lastName: getValues("conLastName"),
        isPrimary: getValues("conIsPrimary"),
        emailOptIn: getValues("conEmailOptIn"),
        notes: getValues("conNotes"),
      },
    },
    session ?? ""
  );

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    postStudent.mutateAsync().then((res) => {
      if (res.data === "Success") {
        queryRefetch();
        setAddStudentModalOpen(false);
        setToastText("Student added!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <DialogHeader title={"Add Student"} />
      <form
        noValidate
        onSubmit={handleSubmit(() => {
          try {
            handleSubmitButtonClick();
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <Flex direction="column" gap="30px" style={{ minWidth: "40vw" }}>
          <MultiStep activeStep={activeStep} setActiveStep={setActiveStep}>
            <MultiStepCard>
              <Group label="Student details" level="secondary">
                <Flex direction="column" gap="8px">
                  <FormField
                    label="First name"
                    error={errors && errors.firstName?.message}
                    required
                  >
                    <TextInput
                      value={watch(`firstName`)}
                      {...register(`firstName`, {
                        required: "Please enter a first name.",
                      })}
                    />
                  </FormField>

                  <FormField label="Middle name">
                    <TextInput
                      value={watch(`middleName`)}
                      {...register(`middleName`)}
                    />
                  </FormField>

                  <FormField
                    label="Last name"
                    error={errors && errors.lastName?.message}
                    required
                  >
                    <TextInput
                      value={watch(`lastName`)}
                      {...register(`lastName`, {
                        required: "Please enter a last name.",
                      })}
                    />
                  </FormField>

                  <FormField label="Preferred name">
                    <TextInput
                      value={watch(`prefName`)}
                      {...register(`prefName`)}
                    />
                  </FormField>

                  <FormField
                    label="Birthday"
                    error={errors && errors.birthday?.message}
                    required
                  >
                    <TextInput
                      type="date"
                      value={watch("birthday")}
                      {...register(`birthday`, {
                        required: "Please select a birthday.",
                      })}
                    />
                  </FormField>

                  <FormField
                    label="Start date"
                    error={errors && errors.startDate?.message}
                    required
                  >
                    <TextInput
                      type="date"
                      value={watch("startDate")}
                      {...register(`startDate`, {
                        required: "Please select a start date.",
                      })}
                    />
                  </FormField>

                  <FormField
                    label="Goals"
                    error={errors && errors.goals?.message}
                    required
                  >
                    <TextInput
                      value={watch(`goals`)}
                      {...register(`goals`, {
                        required: "Please enter a goal.",
                      })}
                    />
                  </FormField>
                </Flex>
              </Group>
            </MultiStepCard>
            <MultiStepCard>
              <Group label="Address information" level="secondary">
                <Flex direction="column" gap="8px">
                  <FormField
                    label="Address 1"
                    error={errors && errors.address1?.message}
                    required
                  >
                    <TextInput
                      value={watch(`address1`)}
                      {...register(`address1`, {
                        required: "Please enter a valid address.",
                      })}
                    />
                  </FormField>

                  <FormField label="Address 2">
                    <TextInput
                      value={watch(`address2`)}
                      {...register(`address2`)}
                    />
                  </FormField>

                  <FormField
                    label="City"
                    error={errors && errors.city?.message}
                    required
                  >
                    <TextInput
                      value={watch(`city`)}
                      {...register(`city`, {
                        required: "Please enter a city.",
                      })}
                    />
                  </FormField>

                  <FormField
                    label="State"
                    error={errors && errors.state?.message}
                    required
                  >
                    <SingleSelect
                      placeholder="Select a state"
                      defaultValue={getValues("state")}
                      onValueChange={(val: string) => setValue(`state`, val)}
                      {...register(`state`, {
                        required: "Please select a state.",
                      })}
                    >
                      <Flex direction="column" style={{ maxHeight: "300px" }}>
                        {statesList.map((state) => {
                          return <SelectItem value={state}>{state}</SelectItem>;
                        })}
                      </Flex>
                    </SingleSelect>
                  </FormField>

                  <FormField
                    label="Zip"
                    error={errors && errors.zip?.message}
                    required
                  >
                    <TextInput
                      value={watch(`zip`)}
                      {...register(`zip`, {
                        required: "Please enter a valid zip code.",
                      })}
                    />
                  </FormField>

                  <FormField
                    label="Country"
                    error={errors && errors.country?.message}
                    required
                  >
                    <TextInput
                      disabled
                      value={watch(`country`)}
                      {...register(`country`, {
                        required: "Please select a country.",
                      })}
                    />
                  </FormField>
                </Flex>
              </Group>
            </MultiStepCard>
            <MultiStepCard>
              <Group label="Contact information" level="secondary">
                <Flex direction="column" gap="8px">
                  <FormField
                    label="Email"
                    error={errors && errors.conEmail?.message}
                    required
                  >
                    <TextInput
                      type="email"
                      required
                      value={watch(`conEmail`)}
                      {...register("conEmail", {
                        required: "Please enter an email.",
                        pattern: {
                          value: emailRegex,
                          message: "Please enter a valid email.",
                        },
                      })}
                    />
                  </FormField>

                  <FormField
                    label="Phone number"
                    error={errors && errors.conPhone?.message}
                    required
                  >
                    <TextInput
                      type="tel"
                      value={watch(`conPhone`)}
                      {...register(`conPhone`, {
                        minLength: {
                          value: 10,
                          message: "Please enter a valid phone number",
                        },
                        maxLength: {
                          value: 10,
                          message: "Please enter a valid phone number",
                        },
                        required: "Please enter a valid phone number.",
                      })}
                    />
                  </FormField>

                  <FormField
                    label="First name"
                    error={errors && errors.conFirstName?.message}
                    required
                  >
                    <TextInput
                      value={watch(`conFirstName`)}
                      {...register(`conFirstName`, {
                        required: "Please enter a first name.",
                      })}
                    />
                  </FormField>

                  <FormField
                    label="Last name"
                    error={errors && errors.conLastName?.message}
                    required
                  >
                    <TextInput
                      value={watch(`conLastName`)}
                      {...register(`conLastName`, {
                        required: "Please enter a last name.",
                      })}
                    />
                  </FormField>

                  <FormField
                    label="Notes"
                    error={errors && errors.conNotes?.message}
                  >
                    <TextInput
                      value={watch(`conNotes`)}
                      {...register(`conNotes`, {
                        maxLength: {
                          value: 140,
                          message: "Please enter up to 140 characters.",
                        },
                      })}
                    />
                  </FormField>
                </Flex>
              </Group>
            </MultiStepCard>
          </MultiStep>
          <Flex justify="right">
            {activeStep === 2 && (
              <Button
                type={"primary"}
                width={"100px"}
                loading={isSubmitting}
                onClick={() => console.log("Submitted!")}
              >
                Submit
              </Button>
            )}
          </Flex>
        </Flex>
      </form>
    </>
  );
};

export default AddStudentModal;
