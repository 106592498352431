import {
  Flex,
  Text,
  DialogHeader,
  SingleSelect,
  SelectItem,
  TextInput,
  FormField,
  Group,
  Button,
  Slider,
  SliderThumb,
  Textarea,
} from "@tycholabs/armillary";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UsePostNewPiece, UsePiecesAutofill } from "../../../../hooks/piece";
import { Event, PieceAutofill } from "../../../../constants/Types";
import { UseEventsList } from "../../../../hooks/event";
import { PieceProficiency } from "../../../../constants";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import { stringToNumber } from "../../../../utils";

export interface AddPieceModalProps {
  setAddPieceModalOpen: Dispatch<SetStateAction<boolean>>;
  setToastVisible: Dispatch<SetStateAction<boolean>>;
  setToastText: Dispatch<SetStateAction<string>>;
  queryRefetch: any;
  lessonId: string;
}

export interface AddPieceFormValues {
  name: string;
  composer: string;
  preparedness: string;
  notes: string;
  prepDeadline: string;
  eventId: string;
}

export const AddPieceModal = ({
  setAddPieceModalOpen,
  setToastVisible,
  setToastText,
  queryRefetch,
  lessonId,
}: AddPieceModalProps) => {
  const theme = useTheme();
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");
  const eventResponse = UseEventsList(
    userId ?? "",
    businessId ?? "",
    session ?? ""
  );
  const eventsData: Event[] = eventResponse?.data?.data;

  const piecesAutofillResponse = UsePiecesAutofill(
    userId ?? "",
    businessId ?? "",
    session ?? ""
  );
  const piecesAutofillData: PieceAutofill =
    piecesAutofillResponse?.data?.data[0];

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AddPieceFormValues>({
    defaultValues: {
      name: undefined,
      composer: undefined,
      preparedness: "0",
      notes: undefined,
      prepDeadline: undefined,
      eventId: undefined,
    },
  });

  // Effect: set the piece notes to the autofill value on component load
  useEffect(() => {
    if (
      piecesAutofillData &&
      piecesAutofillData.autoFillSuggestions.length > 0
    ) {
      const prepVal = stringToNumber(getValues("preparedness"));
      setValue(
        "notes",
        piecesAutofillData.autoFillSuggestions[prepVal].suggestedText[0]
      );
    }
    return () => {};
  }, [piecesAutofillData]);

  const postPiece = UsePostNewPiece(
    userId ?? "",
    businessId ?? "",
    {
      lessonId: lessonId,
      name: getValues("name") ?? null,
      composer:
        getValues("composer") && getValues("composer").length > 0
          ? getValues("composer")
          : null,
      preparedness: getValues("preparedness") ?? "0",
      notes: getValues("notes") ?? null,
      prepDeadline:
        getValues("prepDeadline") && getValues("prepDeadline").length > 0
          ? getValues("prepDeadline")
          : null,
      eventId: getValues("eventId") ?? null,
    },
    session ?? ""
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    postPiece.mutateAsync().then((res) => {
      if (res.data === "Success") {
        queryRefetch();
        setAddPieceModalOpen(false);
        setToastText("Piece added!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <DialogHeader title={"Add Piece"} />
      <form
        noValidate
        onSubmit={handleSubmit(() => {
          try {
            handleSubmitButtonClick();
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <Flex direction="column" gap="30px">
          <Flex direction="row" gap="30px" width="60vw">
            <Group label="Piece details" level="secondary">
              <Flex direction="column" gap="8px">
                <FormField
                  label="Piece name"
                  error={errors && errors.name?.message}
                  required
                >
                  <TextInput
                    value={watch(`name`)}
                    {...register(`name`, {
                      required: "Please enter a piece name.",
                    })}
                  />
                </FormField>

                <FormField label="Composer">
                  <TextInput
                    value={watch(`composer`)}
                    {...register(`composer`)}
                  />
                </FormField>

                <FormField
                  label="Preparedness"
                  error={errors && errors.preparedness?.message}
                  required
                >
                  <Flex direction="row" gap="8px">
                    <Flex style={{ maxWidth: "200px" }}>
                      <Slider
                        defaultValue={[parseInt(watch("preparedness"))]}
                        max={5}
                        min={0}
                        onValueChange={(val: number[]) => {
                          setValue("preparedness", `${val[0]}`);
                          piecesAutofillData &&
                            piecesAutofillData.autoFillSuggestions.length > 0 &&
                            setValue(
                              "notes",
                              `${
                                piecesAutofillData.autoFillSuggestions[val[0]]
                                  ?.suggestedText[0]
                              }`
                            );
                        }}
                      >
                        <SliderThumb />
                      </Slider>
                    </Flex>
                    <Text size="large">{watch("preparedness")}/5</Text>
                    <Text size="large">
                      - {PieceProficiency[parseInt(watch("preparedness"))]}
                    </Text>
                  </Flex>
                </FormField>

                <FormField
                  label="Piece progress notes"
                  limit={{
                    value: watch(`notes`) ? watch("notes").length : 0,
                    cap: 140,
                  }}
                >
                  <Textarea
                    style={{ resize: "none", height: "60px" }}
                    maxLength={140}
                    value={watch(`notes`)}
                    {...register(`notes`, {
                      maxLength: {
                        value: 140,
                        message: "Note cannot be longer than 140 characters.",
                      },
                    })}
                  />
                </FormField>

                <FormField label="Prep deadline">
                  <TextInput
                    type="date"
                    value={watch(`prepDeadline`)}
                    {...register(`prepDeadline`)}
                  />
                </FormField>
                <FormField label="Event">
                  {eventsData && eventsData.length > 0 ? (
                    <SingleSelect
                      disabled={eventsData === undefined}
                      placeholder="Select an event"
                      defaultValue={getValues("eventId")}
                      onValueChange={(val: string) => setValue(`eventId`, val)}
                      {...register(`eventId`)}
                    >
                      {eventsData.map((event: Event) => {
                        return (
                          <SelectItem value={`${event.id}`}>
                            {event.name}
                          </SelectItem>
                        );
                      })}
                    </SingleSelect>
                  ) : (
                    <Link to={"/events"} style={{ textDecoration: "none" }}>
                      <Text
                        size="medium"
                        style={{
                          color: theme.colors.button.primary.background,
                        }}
                      >
                        Click here to add an event.
                      </Text>
                    </Link>
                  )}
                </FormField>
              </Flex>
            </Group>
          </Flex>
          <Button
            type={"primary"}
            width={"100px"}
            loading={isSubmitting}
            onClick={() => console.log("Submitted!")}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </>
  );
};

export default AddPieceModal;
