import { Flex, DialogHeader, Button, Text } from "@tycholabs/armillary";
import { Dispatch, SetStateAction, useState } from "react";
import { User } from "../../../constants/Types";
import { UseDeleteUser } from "../../../hooks/user";

export interface DeleteStaffModalProps {
  setDeleteStaffModalOpen: Dispatch<SetStateAction<boolean>>;
  setToastVisible: Dispatch<SetStateAction<boolean>>;
  setToastText: Dispatch<SetStateAction<string>>;
  queryRefetch: any;
  user: User | null;
}

export const DeleteStaffModal = ({
  setDeleteStaffModalOpen,
  setToastVisible,
  setToastText,
  queryRefetch,
  user,
}: DeleteStaffModalProps) => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const deletePiece = UseDeleteUser(
    userId ?? "",
    businessId ?? "",
    {
      id: user ? user.id : "",
      groupId: user ? `${user.groupId}` : "",
    },
    session ?? ""
  );

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    deletePiece.mutateAsync().then((res) => {
      if (res.data === "Success") {
        queryRefetch();
        setDeleteStaffModalOpen(false);
        setToastText("Staff member removed!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <DialogHeader title={"Remove Staff Member?"} />
      <Text>This action cannot be undone.</Text>
      <Flex direction="row" gap="30px">
        <Button
          type={"secondary"}
          width={"100px"}
          onClick={() => setDeleteStaffModalOpen(false)}
        >
          Cancel
        </Button>
        <Button
          type={"destructive"}
          width={"100px"}
          loading={isSubmitting}
          onClick={() => handleSubmitButtonClick()}
        >
          Delete
        </Button>
      </Flex>
    </>
  );
};

export default DeleteStaffModal;
