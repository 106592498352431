import React from "react";
import "./App.css";
import "./resources/fonts.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./pages/root/Root";
import Staff from "./pages/staff/Staff";
import Students from "./pages/students/Students";
import Events from "./pages/events/Events";
import StudentView from "./pages/students/StudentManagement/StudentView";
import StaffView from "./pages/staff/StaffManagement/StaffView";
import Dashboard from "./pages/dashboard/Dashboard";
import Business from "./pages/business/Business";
import Settings from "./pages/settings/Settings";
import Login from "./pages/login/Login";
import { ThemeProvider } from "styled-components";
import { getArmillaryTheme } from "@tycholabs/armillary";
import { themeChordia } from "./resources/theme";
import ResetPassword from "./pages/reset/ResetPassword";
import LessonView from "./pages/students/LessonManagement/LessonView";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Maintenance from "./pages/errors/Maintenance";
import NotFound from "./pages/errors/NotFound";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
    errorElement: <NotFound />,
  },
  {
    path: "/resetpassword",
    element: <ResetPassword />,
    errorElement: <NotFound />,
  },
  {
    path: "/",
    element: <Root />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: "/staff", element: <Staff /> },
      { path: "/staff/:uid", element: <StaffView /> },
      {
        path: "/students",
        element: <Students />,
        children: [
          { path: "/students/lessons" },
          { path: "/students/studentlist" },
        ],
      },
      {
        path: "/students/student/:uid",
        element: <StudentView />,
      },
      {
        path: "/students/lesson/:uid",
        element: <LessonView />,
      },
      { path: "/events", element: <Events /> },
      { path: "/business", element: <Business /> },
      { path: "/settings", element: <Settings /> },
    ],
    errorElement: <NotFound />,
  },
  {
    path: "/maintenance",
    element: <Maintenance />,
    errorElement: <Maintenance />,
  },
]);

function App() {
  return (
    <ThemeProvider theme={getArmillaryTheme(themeChordia)}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
