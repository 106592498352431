import { Piano, MapPin } from "lucide-react";
import { Event } from "../../../constants/Types";
import { Flex, Heading, Text } from "@tycholabs/armillary";
import { convertDate, convertTo12Hr } from "../../../utils";
import { useTheme } from "styled-components";

export interface EventCardProps {
  event: Event;
}

export const EventCard = ({ event }: EventCardProps) => {
  const theme = useTheme();
  return (
    <Flex direction="column" gap="8px" align="left">
      <Flex direction="row" justify="space-between" align="center">
        <Text size="small">
          {convertDate(event.startDate)}, {convertTo12Hr(event.startTime)}
        </Text>
        <Piano color={theme.colors.text.secondary} size={18} />
      </Flex>

      <Heading
        size="medium"
        style={{
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {event.name}
      </Heading>

      <Flex direction="row" gap="8px" align="left">
        <MapPin size={18} color={theme.colors.text.secondary} />
        <Text
          size="small"
          color="secondary"
          style={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {`${event.address.address1}, ${
            event.address.address2 ? event.address.address2 + ", " : ""
          } ${event.address.city}, ${event.address.state}, ${
            event.address.zip
          }`}
        </Text>
      </Flex>
      <Text size="small" color="secondary">
        Registration deadline: {convertDate(event.regDeadline)}
      </Text>
    </Flex>
  );
};

export default EventCard;
