import {
  Page,
  NavMenu,
  NavMenuButtonRow,
  Flex,
  Heading,
  Card,
  TextInput,
  FormField,
  Button,
  Text,
  colorValues,
} from "@tycholabs/armillary";
import { Content, ContentMain } from "@tycholabs/armillary";
import { Link, useNavigate } from "react-router-dom";
import { PostLoginDTO } from "../../constants/DTO";
import { PostLogin } from "../../hooks/auth";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { emailRegex } from "../../constants";
import { useTheme } from "styled-components";
import { UseMetadata } from "../../hooks/metadata";
import { Metadata } from "../../constants/Types";
import packageJson from "../../../package.json";
import { Loader } from "../../components/Loader";

export interface LoginFormValues {
  userEmail: string;
  pass: string;
}

export const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const metadataResponse = UseMetadata();
  const metadata: Metadata = metadataResponse?.data?.data;

  useEffect(() => {
    if (metadataResponse.isError) {
      navigate("/maintenance");
    }
    if (metadata) {
      console.log(metadata);
      if (metadata.statusActive === false) {
        navigate("/maintenance");
      }
      if (metadata.version === packageJson.version) {
        localStorage.setItem("banner", metadata.banner ?? "");
        localStorage.setItem(
          "bannerActive",
          `${metadata.bannerActive}` ?? "false"
        );
        localStorage.setItem("status", metadata.status ?? "");
        localStorage.setItem(
          "statusActive",
          `${metadata.statusActive}` ?? "false"
        );
        localStorage.setItem("version", metadata.version);
      } else {
        window.location.reload();
      }
    }
  }, [metadataResponse, metadata]);

  const handleLoginClick = () => {
    const loginInfo: PostLoginDTO = {
      userEmail: getValues("userEmail"),
      password: getValues("pass"),
    };
    PostLogin(loginInfo).then((res) => {
      if (res.data.userId) {
        sessionStorage.setItem("userId", res.data.userId);
        sessionStorage.setItem("businessId", res.data.businessId);
        sessionStorage.setItem("permission", res.data.permission);
        sessionStorage.setItem("session", res.data.session);
        navigate("/");
      } else {
        setLoginError(true);
      }
    });
  };

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    defaultValues: {
      userEmail: undefined,
      pass: undefined,
    },
  });
  const [loginError, setLoginError] = useState(false);

  return metadataResponse.isLoading ? (
    <Flex direction="column" gap="0" style={{ height: "100vh" }}>
      <Page direction="row">
        <Content>
          <ContentMain scrollY="scroll">
            <Flex
              style={{ height: "100%" }}
              direction="row"
              justify="center"
              align="center"
            >
              <Loader size={60} />
            </Flex>
          </ContentMain>
        </Content>
      </Page>
    </Flex>
  ) : (
    <Flex direction="column" gap="0" style={{ height: "100vh" }}>
      {localStorage.getItem("bannerActive") === "true" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "center",
            background: colorValues.Saffron,
            padding: "4px 16px",
          }}
        >
          <Text
            weight="semibold"
            style={{
              color: "#000",
              display: "inline",
              whiteSpace: "nowrap",
            }}
          >
            {localStorage.getItem("banner")}
          </Text>
        </div>
      )}
      <div style={{ borderBottom: `1px solid ${theme.colors.layout.border}` }}>
        <NavMenu title="Chordia BETA">
          <NavMenuButtonRow></NavMenuButtonRow>
        </NavMenu>
      </div>
      <Page direction="row">
        <Content>
          <ContentMain scrollY="scroll">
            <Flex direction="row" justify="center" align="center">
              <Card style={{ width: "50%", minWidth: "400px" }}>
                <Heading>Login</Heading>
                <form
                  noValidate
                  onSubmit={handleSubmit(() => {
                    try {
                      handleLoginClick();
                    } catch (e) {
                      console.log(e);
                    }
                  })}
                >
                  <Flex direction="column" gap="8px" align="center">
                    <FormField
                      required
                      label="Email"
                      error={errors && errors.userEmail?.message}
                      width="250px"
                    >
                      <TextInput
                        required
                        type="email"
                        value={watch(`userEmail`)}
                        {...register("userEmail", {
                          required: "Please enter an email.",
                          pattern: {
                            value: emailRegex,
                            message: "Please enter a valid email.",
                          },
                        })}
                      />
                    </FormField>
                    <FormField required label="Password" width="250px">
                      <TextInput
                        type="password"
                        required
                        value={watch(`pass`)}
                        {...register(`pass`, {
                          required: "Please enter a password.",
                        })}
                      />
                    </FormField>
                    {loginError && (
                      <Text color="error">Incorrect username or password</Text>
                    )}
                    <Button
                      width="auto"
                      type="primary"
                      onClick={() => console.log("Submitted!")}
                    >
                      Sign In
                    </Button>
                  </Flex>
                </form>

                <Link style={{ textDecoration: "none" }} to={"/resetpassword"}>
                  <Text
                    size="small"
                    style={{ color: theme.colors.button.primary.background }}
                  >
                    Forgot password?
                  </Text>
                </Link>
              </Card>
            </Flex>
          </ContentMain>
        </Content>
      </Page>
    </Flex>
  );
};

export default Login;
