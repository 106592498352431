import {
  Page,
  NavMenu,
  Navbar,
  NavbarButtonColumn,
  NavbarButton,
  NavMenuButtonRow,
  NavMenuOptions,
  NavMenuItem,
  Flex,
  colorValues,
  Text,
  Button,
  Dialog,
  DialogTrigger,
  DialogContent,
  Toast,
  Avatar,
  AvatarFallback,
  getUserColor,
} from "@tycholabs/armillary";
import { Content, ContentMain } from "@tycholabs/armillary";
import {
  Link,
  Outlet,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  LayoutDashboard,
  Users,
  CalendarDays,
  Backpack,
  University,
  Settings,
} from "lucide-react";
import { PostLogoutDTO } from "../../constants/DTO";
import { PostLogout } from "../../hooks/auth";
import { useEffect, useState } from "react";
import ReportBugModal from "./ReportBugModal";
import { useTheme } from "styled-components";
import { UseMetadata } from "../../hooks/metadata";
import { Metadata } from "../../constants/Types";
import packageJson from "../../../package.json";
import { Loader } from "../../components/Loader";

export const Root = () => {
  const auth = { session: sessionStorage.getItem("session") };
  const navigate = useNavigate();
  const theme = useTheme();

  const metadataResponse = UseMetadata();
  const metadata: Metadata = metadataResponse?.data?.data;

  useEffect(() => {
    if (metadataResponse.isError) {
      navigate("/maintenance");
    }
    if (metadata) {
      console.log(metadata);
      if (metadata.statusActive === false) {
        navigate("/maintenance");
      }
      if (metadata.version === packageJson.version) {
        localStorage.setItem("banner", metadata.banner ?? "");
        localStorage.setItem(
          "bannerActive",
          `${metadata.bannerActive}` ?? "false"
        );
        localStorage.setItem("status", metadata.status ?? "");
        localStorage.setItem(
          "statusActive",
          `${metadata.statusActive}` ?? "false"
        );
        localStorage.setItem("version", metadata.version);
      } else {
        window.location.reload();
      }
    }
  }, [metadataResponse, metadata]);

  const [reportBugModalOpen, setReportBugModalOpen] = useState(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("Saved!");

  const signOutButtonClicked = () => {
    const logoutInfo: PostLogoutDTO = {
      userId: sessionStorage.getItem("userId") ?? "",
      session: sessionStorage.getItem("session") ?? "",
    };
    PostLogout(logoutInfo).then(() => {
      sessionStorage.clear();
      navigate("/login");
    });
  };

  const navItems: NavMenuItem[] = [
    {
      title: "Dashboard",
      icon: <LayoutDashboard size={18} />,
      link: `/`,
    },
    {
      title: "Staff",
      icon: <Users size={18} />,
      link: `/staff`,
    },
    {
      title: "Events",
      icon: <CalendarDays size={18} />,
      link: `/events`,
    },
    {
      title: "Students",
      icon: <Backpack size={18} />,
      link: `/students`,
    },
    {
      title: "Business",
      icon: <University size={18} />,
      link: `/business`,
    },
    {
      title: "Settings",
      icon: <Settings size={18} />,
      link: `/settings`,
    },
  ];

  const location = useLocation();

  return metadataResponse.isLoading ? (
    <Flex direction="column" gap="0" style={{ height: "100vh" }}>
      <Page direction="row">
        <Content>
          <ContentMain scrollY="scroll">
            <Flex
              style={{ height: "100%" }}
              direction="row"
              justify="center"
              align="center"
            >
              <Loader size={60} />
            </Flex>
          </ContentMain>
        </Content>
      </Page>
    </Flex>
  ) : auth.session ? (
    <Flex direction="column" gap="0" style={{ height: "100vh" }}>
      {localStorage.getItem("bannerActive") === "true" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "center",
            background: colorValues.Saffron,
            padding: "4px 16px",
          }}
        >
          <Text
            weight="semibold"
            style={{
              color: "#000",
              display: "inline",
              whiteSpace: "nowrap",
            }}
          >
            {localStorage.getItem("banner")}
          </Text>
        </div>
      )}

      <div style={{ borderBottom: `3px solid ${theme.colors.layout.accent}` }}>
        <NavMenu title="Chordia BETA">
          <NavMenuButtonRow></NavMenuButtonRow>
          <NavMenuOptions>
            <Dialog
              open={reportBugModalOpen}
              onOpenChange={setReportBugModalOpen}
            >
              <DialogTrigger asChild>
                <Button width="120px" type="secondary">
                  Report a bug
                </Button>
              </DialogTrigger>
              <DialogContent
                onPointerDownOutside={(e: any) => e.preventDefault()}
              >
                <ReportBugModal
                  setReportBugModalOpen={setReportBugModalOpen}
                  setToastVisible={setToastVisible}
                  setToastText={setToastText}
                />
              </DialogContent>
            </Dialog>

            <Button
              type="secondary"
              width="120px"
              onClick={() => signOutButtonClicked()}
            >
              Sign out
            </Button>

            {/* <Avatar
                background={getUserColor(
                  `${sessionStorage.getItem("userId")}563hf`
                )}
                size="40px"
              >
                <AvatarFallback>VF</AvatarFallback>
              </Avatar> */}
          </NavMenuOptions>
        </NavMenu>
      </div>
      <Page direction="row">
        <Navbar width="18vw">
          <NavbarButtonColumn>
            <Flex style={{ height: "24px", paddingTop: "18px" }} />
            {navItems.map((item) => (
              <Link style={{ textDecoration: "none" }} to={item.link}>
                <NavbarButton
                  title={item.title}
                  icon={item.icon}
                  selected={"/" + location.pathname.split("/")[1] === item.link}
                />
              </Link>
            ))}
          </NavbarButtonColumn>
        </Navbar>
        <Content>
          <ContentMain scrollY="scroll">
            <Outlet />
          </ContentMain>
        </Content>
        <Toast title={toastText} open={toastVisible} />
      </Page>
    </Flex>
  ) : (
    <Navigate to="/login" />
  );
};

export default Root;
