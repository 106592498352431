import {
  Button,
  DataTableColumnHeader,
  Flex,
  Text,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuItem,
} from "@tycholabs/armillary";
import { MoreHorizontal, Trash } from "lucide-react";
import { Link } from "react-router-dom";
import { convertDate, getDaysList } from "../../../utils";
import { Dispatch, SetStateAction } from "react";
import { Student } from "../../../constants/Types";

export const getStudentsColumns = (
  setStudentToDelete: Dispatch<SetStateAction<Student | null>>,
  setDeleteStudentModalOpen: Dispatch<SetStateAction<boolean>>,
  permission: string | null
) => {
  return [
    {
      id: "firstName",
      accessorKey: "firstName",
      meta: "First name",
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="First name" />
      ),
    },
    // {
    //   id: "middleName",
    //   accessorKey: "middleName",
    //   header: ({ column }: any) => (
    //     <DataTableColumnHeader column={column} title="Middle name" />
    //   ),
    //   cell: ({ row }: any) => {
    //     return row.original.middleName ?? <Text color="secondary">-</Text>;
    //   },
    // },
    {
      id: "lastName",
      accessorKey: "lastName",
      meta: "Last name",
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Last name" />
      ),
    },
    // {
    //   id: "prefName",
    //   accessorKey: "prefName",
    //   header: ({ column }: any) => (
    //     <DataTableColumnHeader column={column} title="Preferred name" />
    //   ),
    //   cell: ({ row }: any) => {
    //     return row.original.prefName ?? <Text color="secondary">-</Text>;
    //   },
    // },
    {
      id: "lessons.0.location.name",
      accessorKey: "lessons.0.location.name",
      meta: "Location",
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Location" />
      ),
    },
    {
      id: "lessons.0.instrument",
      accessorKey: "lessons.0.instrument",
      meta: "Instrument",
      enableSorting: false,
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Instrument" />
      ),
      cell: ({ row }: any) => {
        return row.original.lessons.map(
          (lesson: { instrument: number }, i: number) => (
            <>
              {lesson.instrument}
              {i === row.original.lessons.length - 1 ? "" : ", "}
            </>
          )
        );
      },
    },
    {
      accessorKey: "lessons.0.days",
      meta: "Days",
      enableSorting: false,
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Days" />
      ),
      cell: ({ row }: any) => {
        if (row.original.lessons.length > 0) {
          return (
            <Text>
              {getDaysList(
                row.original.lessons.map((lesson: any, i: any) => {
                  return lesson.days.split("");
                }),
                true
              )}
            </Text>
          );
        } else {
          return <Text color="secondary">-</Text>;
        }
      },
    },
    {
      accessorKey: "startDate",
      meta: "Started",
      enableSorting: true,
      header: ({ column }: any) => (
        <DataTableColumnHeader
          column={column}
          title="Started"
          justify="right"
        />
      ),
      cell: ({ row }: any) => {
        return (
          <Flex justify="right">{convertDate(row.original.startDate)}</Flex>
        );
      },
    },
    {
      id: "actions",
      meta: "Actions",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Options" justify="left" />
      ),
      cell: ({ row }: any) => {
        return (
          <Flex direction="row" gap="4px">
            <Link
              style={{ textDecoration: "none" }}
              to={`/students/student/${row.original.id}`}
            >
              <Button type="outline" width="auto">
                View
              </Button>
            </Link>
            {permission === "1" && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button width="36px" padding="0px" type="ghost">
                    <MoreHorizontal width="24px" height="24px" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuContent>
                    <DropdownMenuLabel>Student options</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    {permission === "1" && (
                      <>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem
                          onClick={() => {
                            setStudentToDelete(row.original);
                            setDeleteStudentModalOpen(true);
                          }}
                        >
                          <Trash width="16px" height="16px" />
                          Delete
                        </DropdownMenuItem>
                      </>
                    )}
                  </DropdownMenuContent>
                </DropdownMenuPortal>
              </DropdownMenu>
            )}
          </Flex>
        );
      },
    },
  ];
};
