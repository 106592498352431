import {
  Flex,
  DialogHeader,
  Text,
  Group,
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  DataOverlay,
} from "@tycholabs/armillary";
import { Event, Piece } from "../../../constants/Types";
import { Calendar, MapPin } from "lucide-react";
import { UsePiecesListByEvent } from "../../../hooks/piece";
import { convertTo12Hr } from "../../../utils";
import { useTheme } from "styled-components";

export interface ViewEventModalProps {
  event: Event | null;
}

export const ViewEventModal = ({ event }: ViewEventModalProps) => {
  const theme = useTheme();
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");

  const eventInfo = event ?? {
    id: "",
    name: "",
    address: {
      id: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
    startTime: "",
    startDate: "",
    regDeadline: "",
  };

  const piecesResponse = UsePiecesListByEvent(
    userId ?? "",
    businessId ?? "",
    event ? event.id : "",
    session ?? ""
  );
  const piecesData: Piece[] = piecesResponse?.data?.data;

  return (
    <>
      <DialogHeader title={eventInfo.name} />
      <Flex
        direction='column'
        gap='30px'
        style={{ minWidth: "50vw", maxHeight: "80vh", overflowY: "scroll" }}
      >
        <Group label='Event details' level='secondary'>
          <Text size='medium'>
            {new Date(eventInfo.startDate).toDateString()},{" "}
            {convertTo12Hr(eventInfo.startTime)}
          </Text>
          <Flex direction='row' gap='8px' align='left'>
            <MapPin size={18} color={theme.colors.text.secondary} />
            <Text
              size='medium'
              color='secondary'
              style={{
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {`${eventInfo.address.address1}, ${
                eventInfo.address.address2
                  ? eventInfo.address.address2 + ", "
                  : ""
              } ${eventInfo.address.city}, ${eventInfo.address.state}, ${
                eventInfo.address.zip
              }`}
            </Text>
          </Flex>
          <Flex direction='row' gap='8px' align='left'>
            <Calendar size={18} color={theme.colors.text.secondary} />
            <Text size='medium' color='secondary'>
              Registration deadline:{" "}
              {new Date(eventInfo.regDeadline).toDateString()}
            </Text>
          </Flex>
        </Group>
        <DataOverlay
          loading={piecesResponse.isLoading}
          error={piecesResponse.isError}
          exists={piecesData && piecesData.length > 0}
        >
          {piecesData && (
            <Group label='Pieces' level='secondary'>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead></TableHead>
                    <TableHead>Student</TableHead>
                    <TableHead>Name</TableHead>
                    <TableHead>Composer</TableHead>
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {piecesData.map((piece, i) => (
                    <>
                      <TableRow key={piece.id}>
                        <TableCell>
                          <Text color='secondary'>{i + 1}</Text>
                        </TableCell>
                        <TableCell>Student Here</TableCell>
                        <TableCell>{piece.name}</TableCell>
                        <TableCell>
                          {piece.composer ?? <Text color='secondary'>-</Text>}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Group>
          )}
        </DataOverlay>
      </Flex>
    </>
  );
};

export default ViewEventModal;
