import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoint } from "../constants/Endpoints";
import {
  AddContactDTO,
  AddStudentDTO,
  DeleteContactDTO,
  DeleteStudentDTO,
  UpdateStudentDTO,
} from "../constants/DTO";

const GetStudentsList = async (
  userId: string,
  businessId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/students`, {
      params: { user: userId, business: businessId, session: sessionId },
    })
    .then((res) => {
      return res;
    });
};

export const UseStudentsList = (
  userId: string,
  businessId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-GetStudentsList`],
    queryFn: () => GetStudentsList(userId, businessId, sessionId),
  });
  return response;
};

const GetStudent = async (
  userId: string,
  businessId: string,
  studentId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/students`, {
      params: {
        user: userId,
        business: businessId,
        student: studentId,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseStudent = (
  userId: string,
  businessId: string,
  studentId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-${studentId}-GetStudent`],
    queryFn: () => GetStudent(userId, businessId, studentId, sessionId),
  });
  return response;
};

const PostNewStudent = async (
  userId: string,
  businessId: string,
  student: AddStudentDTO,
  sessionId: string
) => {
  return await axios
    .post(
      `${endpoint}/students`,
      {
        student: student.student,
        contact: student.contact,
        address: student.address,
      },
      {
        params: {
          user: userId,
          business: businessId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePostNewStudent = (
  userId: string,
  businessId: string,
  student: AddStudentDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PostNewStudent`],
    mutationFn: () => PostNewStudent(userId, businessId, student, sessionId),
  });
  return response;
};

const PutUpdateStudent = async (
  userId: string,
  businessId: string,
  student: UpdateStudentDTO,
  sessionId: string
) => {
  return await axios
    .put(
      `${endpoint}/students`,
      {
        student: student.student,
        address: student.address,
      },
      {
        params: {
          user: userId,
          business: businessId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePutUpdateStudent = (
  userId: string,
  businessId: string,
  student: UpdateStudentDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PutUpdateStudent`],
    mutationFn: () => PutUpdateStudent(userId, businessId, student, sessionId),
  });
  return response;
};

const DeleteStudent = async (
  userId: string,
  businessId: string,
  student: DeleteStudentDTO,
  sessionId: string
) => {
  return await axios
    .delete(`${endpoint}/students`, {
      data: {
        user: userId,
        business: businessId,
        student: student,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseDeleteStudent = (
  userId: string,
  businessId: string,
  student: DeleteStudentDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-DeleteStudent`],
    mutationFn: () => DeleteStudent(userId, businessId, student, sessionId),
  });
  return response;
};

const GetContacts = async (
  userId: string,
  businessId: string,
  studentId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/contacts`, {
      params: {
        user: userId,
        business: businessId,
        student: studentId,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseContacts = (
  userId: string,
  businessId: string,
  studentId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-${studentId}-GetContacts`],
    queryFn: () => GetContacts(userId, businessId, studentId, sessionId),
  });
  return response;
};

const PostNewContact = async (
  userId: string,
  businessId: string,
  contact: AddContactDTO,
  sessionId: string
) => {
  return await axios
    .post(
      `${endpoint}/contacts`,
      {
        contact,
      },
      {
        params: {
          user: userId,
          business: businessId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePostNewContact = (
  userId: string,
  businessId: string,
  contact: AddContactDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PostNewContact`],
    mutationFn: () => PostNewContact(userId, businessId, contact, sessionId),
  });
  return response;
};

const DeleteContact = async (
  userId: string,
  businessId: string,
  contact: DeleteContactDTO,
  sessionId: string
) => {
  return await axios
    .delete(`${endpoint}/contacts`, {
      data: {
        user: userId,
        business: businessId,
        contact: contact,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseDeleteContact = (
  userId: string,
  businessId: string,
  contact: DeleteContactDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-DeleteContact`],
    mutationFn: () => DeleteContact(userId, businessId, contact, sessionId),
  });
  return response;
};
