import { Instrument, Lesson, User } from "../../../constants/Types";
import {
  Avatar,
  AvatarFallback,
  Button,
  Flex,
  getUserColor,
  Heading,
  Text,
  Separator,
  Card,
  Group,
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableBody,
  TableCell,
  DataOverlay,
  Dialog,
  DialogTrigger,
  DialogContent,
  Toast,
} from "@tycholabs/armillary";
import { MapPin, Music, Star, Mail, Plus } from "lucide-react";
import { UseUser, UseUserLessons } from "../../../hooks/user";
import { useParams, Link } from "react-router-dom";
import { Roles } from "../../../constants";
import AddLessonModal from "../../students/LessonManagement/AddLessonModal";
import { useState } from "react";
import { convertTo12Hr, getDaysList } from "../../../utils";
import { useTheme } from "styled-components";

export const StaffView = () => {
  const theme = useTheme();
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const permission = sessionStorage.getItem("permission");
  const session = sessionStorage.getItem("session");
  let params = useParams();
  const param = params.uid as string;
  const userResponse = UseUser(
    userId ?? "",
    businessId ?? "",
    param,
    session ?? ""
  );
  const userData: User = userResponse?.data?.data[0];
  const lessonsResponse = UseUserLessons(
    userId ?? "",
    businessId ?? "",
    param,
    session ?? ""
  );
  const lessonsData: Lesson[] = lessonsResponse?.data?.data;

  console.log({ userId, permission });

  const [addLessonModalOpen, setAddLessonModalOpen] = useState(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("Saved!");

  return (
    <Flex
      direction="row"
      gap="30px"
      style={{ maxWidth: "64vw", boxSizing: "border-box" }}
    >
      <DataOverlay
        loading={userResponse.isLoading}
        error={userResponse.isError}
        exists={userData !== null}
      >
        {userData && (
          <Flex direction="column" gap="16px">
            <Heading size="medium">
              View and edit {userData.firstName}'s details.
            </Heading>
            <Card style={{ width: "100%" }}>
              <Flex direction="row" align="center" gap="30px">
                <Avatar
                  background={getUserColor(`${userData.id}563hf`)}
                  size="80px"
                >
                  <AvatarFallback>
                    {`${userData.firstName[0]}${userData.lastName[0]}`}
                  </AvatarFallback>
                </Avatar>
                <Heading>{`${userData.firstName} ${userData.lastName}`}</Heading>
              </Flex>

              <Group label="Details" level="secondary">
                <Flex direction="row" gap="8px" align="center">
                  <MapPin
                    height={16}
                    width={16}
                    color={theme.colors.text.primary}
                  />
                  <Text color="secondary">Address:</Text>
                  <Text color="primary">
                    {userData.address.address1},{" "}
                    {userData.address.address2
                      ? userData.address.address2 + ", "
                      : ""}
                    {userData.address.city}, {userData.address.state},{" "}
                    {userData.address.zip}
                  </Text>
                </Flex>
                <Flex direction="row" gap="8px" align="center">
                  <Mail
                    height={16}
                    width={16}
                    color={theme.colors.text.primary}
                  />
                  <Text color="secondary">Email:</Text>
                  <Text color="primary">{userData.email}</Text>
                </Flex>
                <Flex direction="row" gap="8px" align="center">
                  <Star
                    height={16}
                    width={16}
                    color={theme.colors.text.primary}
                  />
                  <Text color="secondary">Role:</Text>
                  <Text color="primary">{Roles[userData.groupId - 1]}</Text>
                </Flex>
              </Group>
              <Separator />

              <Group label="Instruments" level="secondary">
                <Flex direction="row" gap="8px" align="center">
                  <Music
                    height={16}
                    width={16}
                    color={theme.colors.text.primary}
                  />
                  <Text color="primary">
                    {userData.instruments.map(
                      (instrument: Instrument, i: number) => (
                        <>
                          {instrument.name}
                          {i === userData.instruments.length - 1 ? "" : ", "}
                        </>
                      )
                    )}
                  </Text>
                </Flex>
              </Group>
            </Card>

            <Card style={{ width: "100%" }}>
              <Flex direction="column" gap="8px">
                <Heading size="medium">Lessons</Heading>
                <DataOverlay
                  loading={lessonsResponse.isLoading}
                  error={lessonsResponse.isError}
                  exists={lessonsData && lessonsData.length > 0}
                >
                  {lessonsData && (
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>Student</TableHead>
                          <TableHead>Location</TableHead>
                          <TableHead>Instrument</TableHead>
                          <TableHead>Length</TableHead>
                          <TableHead>Days</TableHead>
                          <TableHead>Time</TableHead>
                          <TableHead></TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {lessonsData.map((lesson) => (
                          <TableRow key={lesson.id}>
                            <TableCell>{lesson.student.name}</TableCell>
                            <TableCell>{lesson.location.name}</TableCell>
                            <TableCell>{lesson.instrument}</TableCell>
                            <TableCell>{lesson.length}</TableCell>
                            <TableCell>
                              {getDaysList(lesson.days.split(""))}
                            </TableCell>
                            <TableCell>{convertTo12Hr(lesson.time)}</TableCell>
                            <TableCell>
                              {(permission === "1" ||
                                `${lesson.teacher.id}` === userId) && (
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={`/students/lesson/${lesson.id}`}
                                >
                                  <Button type="outline">View</Button>
                                </Link>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </DataOverlay>
                {permission === "1" && (
                  <Dialog
                    open={addLessonModalOpen}
                    onOpenChange={setAddLessonModalOpen}
                  >
                    <DialogTrigger asChild>
                      <Button
                        type="outline"
                        height="40px"
                        padding={"0px"}
                        style={{
                          borderStyle: "dashed",
                          borderWidth: 2,
                          textDecoration: "none",
                          borderRadius: "12px",
                          boxShadow: theme.colors.shadow.small,
                        }}
                      >
                        <Flex
                          direction={"row"}
                          gap={"8px"}
                          align="center"
                          justify="center"
                          style={{ margin: "auto" }}
                        >
                          <Plus size={30} color={theme.colors.text.secondary} />
                          <Text size="medium" weight="bold" color="secondary">
                            Add lesson
                          </Text>
                        </Flex>
                      </Button>
                    </DialogTrigger>
                    <DialogContent
                      onPointerDownOutside={(e: any) => e.preventDefault()}
                    >
                      <AddLessonModal
                        setAddLessonModalOpen={setAddLessonModalOpen}
                        setToastVisible={setToastVisible}
                        setToastText={setToastText}
                        queryRefetch={lessonsResponse.refetch}
                        selectedStaffId={userData.id}
                        selectedStaffName={`${userData.firstName} ${userData.lastName}`}
                      />
                    </DialogContent>
                  </Dialog>
                )}
              </Flex>
            </Card>
          </Flex>
        )}
      </DataOverlay>
      <Toast title={toastText} open={toastVisible} />
    </Flex>
  );
};

export default StaffView;
