import { Lesson } from "../../../constants/Types";
import {
  Avatar,
  AvatarFallback,
  Flex,
  getUserColor,
  Heading,
  Card,
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
  DataOverlay,
  Dialog,
  Toast,
  Text,
  DialogContent,
  Button,
  getContrastTextColor,
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  BreadcrumbPage,
} from "@tycholabs/armillary";
import { Calendar, Clock, Mail, MapPin, Send, Slash } from "lucide-react";
import {
  UsePostSendEmail,
  UseLesson,
  UseLessonSentToday,
} from "../../../hooks/lesson";
import { Link, useParams } from "react-router-dom";
import NotesView from "./notes/NotesView";
import ProgressView from "./progress/ProgressView";
import EditLessonModal from "./EditLessonModal";
import { useState } from "react";
import PiecesView from "./pieces/PiecesView";
import { convertTo12Hr, getDaysList } from "../../../utils";
import { EmailFrequency } from "../../../constants";
import { useTheme } from "styled-components";

export const LessonView = () => {
  const theme = useTheme();
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");
  let params = useParams();
  const param = params.uid as string;
  const lessonResponse = UseLesson(
    userId ?? "",
    businessId ?? "",
    param,
    session ?? ""
  );
  const lessonData: Lesson = lessonResponse?.data?.data[0];
  const lessonSentResponse = UseLessonSentToday(
    userId ?? "",
    businessId ?? "",
    param,
    session ?? ""
  );
  const lessonSentData: boolean = lessonSentResponse?.data?.data;

  const [editLessonModalOpen, setEditLessonModalOpen] = useState(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("Saved!");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const emailLesson = UsePostSendEmail(
    userId ?? "",
    businessId ?? "",
    {
      lesson: lessonData ? lessonData.id : "",
      student: lessonData ? lessonData.student.id : "",
    },
    session ?? ""
  );

  const handleSendEmailButtonClick = () => {
    setIsSubmitting(true);
    emailLesson.mutateAsync().then((res) => {
      if (res.data === "Success") {
        lessonSentResponse.refetch();
        setIsSubmitting(false);
        setToastText("Lesson email sent!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <Flex
      direction="column"
      gap="0px"
      style={{ maxWidth: "64vw", boxSizing: "border-box" }}
    >
      {lessonData && (
        <Breadcrumb>
          <BreadcrumbList style={{ padding: "0px", margin: "8px" }}>
            <BreadcrumbItem>
              <Link style={{ textDecoration: "none" }} to="/students/lessons">
                <Text color="secondary" size="small" weight="semiBold">
                  Lessons
                </Text>
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>{`${
              lessonData.student.name
            }'s ${lessonData.instrument.toLowerCase()} lesson`}</BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      )}

      <DataOverlay
        loading={lessonResponse.isLoading}
        error={lessonResponse.isError}
        exists={lessonData !== null}
      >
        {lessonData && (
          <Flex direction="column" gap="16px">
            <Card>
              <Flex direction="row" gap="30px">
                <Avatar
                  background={getUserColor(`${lessonData.student.id}563hf`)}
                  size="80px"
                >
                  <AvatarFallback>
                    {`${lessonData.student.name[0]}${lessonData.student.name[1]}`}
                  </AvatarFallback>
                </Avatar>
                <Flex direction="column" align="left">
                  <Heading>
                    {lessonData.student.name} - {lessonData.instrument}
                  </Heading>
                  <Heading color="secondary" size="small" weight="semibold">
                    Teacher: {lessonData.teacher.name}
                  </Heading>
                  <Flex justify="left" align="center" gap="4px">
                    <MapPin size={20} color={theme.colors.text.secondary} />
                    <Heading color="secondary" size="small" weight="semibold">
                      {lessonData.location.name}
                    </Heading>
                  </Flex>
                  <Flex align="center" gap="4px">
                    <Calendar size={20} color={theme.colors.text.secondary} />
                    <Heading color="secondary" size="small" weight="semibold">
                      {lessonData.days
                        ? getDaysList(lessonData.days.split(""))
                        : "-"}
                    </Heading>
                  </Flex>
                  <Flex align="center" gap="4px">
                    <Clock size={20} color={theme.colors.text.secondary} />
                    <Heading color="secondary" size="small" weight="semibold">
                      {lessonData.time
                        ? `${convertTo12Hr(lessonData.time)}, ${
                            lessonData.length
                          } min.`
                        : "-"}
                    </Heading>
                  </Flex>
                  <Flex align="center" gap="4px">
                    <Mail size={20} color={theme.colors.text.secondary} />
                    <Heading color="secondary" size="small" weight="semibold">
                      {lessonData.autoSendEmails
                        ? `Emails will be sent on ${
                            EmailFrequency[
                              parseInt(lessonData.emailScheduleId) - 1
                            ] === "Weekly"
                              ? "Sunday mornings"
                              : "the first of each month"
                          }`
                        : "Emails will be sent manually"}
                    </Heading>
                  </Flex>
                </Flex>
                <Flex direction="row" gap="8px" width="auto">
                  <Button
                    disabled={
                      lessonSentData === false ||
                      lessonSentResponse.isLoading === true ||
                      lessonSentResponse.isFetching === true
                    }
                    type={"primary"}
                    width="134px"
                    loading={isSubmitting}
                    onClick={() => {
                      lessonSentData === false ||
                      lessonSentResponse.isLoading === true ||
                      lessonSentResponse.isFetching === true
                        ? console.log("Nope!")
                        : handleSendEmailButtonClick();
                    }}
                  >
                    <Flex direction="row" align="center" gap="6px">
                      <Send
                        color={
                          !lessonSentData
                            ? theme.colors.text.subdued
                            : getContrastTextColor(
                                theme.colors.button.primary.background
                              )
                        }
                        size={18}
                      />
                      <Text
                        weight="bold"
                        size="small"
                        color="contrast"
                        style={{
                          color: !lessonSentData
                            ? theme.colors.text.subdued
                            : getContrastTextColor(
                                theme.colors.button.primary.background
                              ),
                        }}
                      >
                        Send Email
                      </Text>
                    </Flex>
                  </Button>

                  <Button
                    type="primary"
                    width="auto"
                    onClick={() => {
                      setEditLessonModalOpen(true);
                    }}
                  >
                    Edit
                  </Button>
                </Flex>
              </Flex>
            </Card>
            <Card>
              <Tabs defaultValue="notes">
                <TabsList>
                  <TabsTrigger value="notes">Lesson notes</TabsTrigger>
                  {lessonData.instrument ===
                    "This is disabled, come back later" && (
                    <TabsTrigger value="progress">Progress</TabsTrigger>
                  )}
                  <TabsTrigger value="pieces">Pieces</TabsTrigger>
                </TabsList>
                <TabsContent value="notes">
                  <NotesView lesson={lessonData.id} />
                </TabsContent>
                {lessonData.instrument ===
                  "This is disabled, come back later" && (
                  <TabsContent value="progress">
                    <ProgressView />
                  </TabsContent>
                )}
                <TabsContent value="pieces">
                  <PiecesView lesson={lessonData.id} />
                </TabsContent>
              </Tabs>
            </Card>
          </Flex>
        )}
      </DataOverlay>
      <Toast title={toastText} open={toastVisible} />
      <Dialog open={editLessonModalOpen} onOpenChange={setEditLessonModalOpen}>
        <DialogContent onPointerDownOutside={(e: any) => e.preventDefault()}>
          <EditLessonModal
            setEditLessonModalOpen={setEditLessonModalOpen}
            setToastVisible={setToastVisible}
            setToastText={setToastText}
            queryRefetch={lessonResponse.refetch}
            lesson={lessonData}
          />
        </DialogContent>
      </Dialog>
    </Flex>
  );
};

export default LessonView;
