import {
  Flex,
  Heading,
  Card,
  DataOverlay,
  Table,
  TableRow,
  TableHeader,
  TableHead,
  TableCell,
  DropdownMenu,
  DropdownMenuTrigger,
  Button,
  TableBody,
  DropdownMenuPortal,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuItem,
  Dialog,
  DialogTrigger,
  DialogContent,
  Text,
  Toast,
  Progress,
} from "@tycholabs/armillary";
import { UsePiecesList } from "../../../../hooks/piece";
import { Piece } from "../../../../constants/Types";
import {
  CornerDownRight,
  MoreHorizontal,
  Pencil,
  Plus,
  Trash,
} from "lucide-react";
import { useState } from "react";
import AddPieceModal from "./AddPieceModal";
import DeletePieceModal from "./DeletePieceModal";
import EditPieceModal from "./EditPieceModal";
import { PieceProficiency } from "../../../../constants";
import { useTheme } from "styled-components";

export interface PiecesViewProps {
  lesson: string;
}

export const PiecesView = ({ lesson }: PiecesViewProps) => {
  const theme = useTheme();
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");
  const piecesResponse = UsePiecesList(
    userId ?? "",
    businessId ?? "",
    lesson,
    session ?? ""
  );
  const piecesData: Piece[] = piecesResponse?.data?.data;

  const [addPieceModalOpen, setAddPieceModalOpen] = useState(false);
  const [editPieceModalOpen, setEditPieceModalOpen] = useState(false);
  const [deletePieceModalOpen, setDeletePieceModalOpen] = useState(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("Saved!");

  const [pieceToEdit, setPieceToEdit] = useState<Piece | null>(null);
  const [pieceToDelete, setPieceToDelete] = useState<Piece | null>(null);

  return (
    <>
      <Card>
        <Flex direction="column" gap="8px">
          <Flex direction="column" gap="0">
            <Heading>Pieces</Heading>
            <Heading color="secondary" size="small" weight="semibold">
              View your student's pieces and their progress.
            </Heading>
          </Flex>
          <DataOverlay
            loading={piecesResponse.isLoading}
            error={piecesResponse.isError}
            exists={piecesData && piecesData.length > 0}
          >
            {piecesData && piecesData.length > 0 && (
              <>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead></TableHead>
                      <TableHead>Name</TableHead>
                      <TableHead>Composer</TableHead>
                      <TableHead>Event</TableHead>
                      <TableHead>Preparedness</TableHead>
                      <TableHead></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {piecesData.map((piece, i) => (
                      <>
                        <TableRow
                          key={piece.id}
                          style={
                            piece.notes
                              ? {
                                  borderWidth: "0px 0px 1px 0px",
                                  borderStyle: "dashed",
                                }
                              : {}
                          }
                        >
                          <TableCell>
                            <Text color="secondary">{i + 1}</Text>
                          </TableCell>
                          <TableCell>{piece.name}</TableCell>
                          <TableCell>
                            {piece.composer ?? <Text color="secondary">-</Text>}
                          </TableCell>
                          <TableCell>
                            {piece.event.name ?? (
                              <Text color="secondary">-</Text>
                            )}
                          </TableCell>
                          <TableCell>
                            <Flex direction="column" align="center">
                              <Progress
                                style={{ height: "10px" }}
                                value={parseInt(piece.preparedness) * 20}
                              />
                              <Text>
                                {PieceProficiency[parseInt(piece.preparedness)]}
                              </Text>
                            </Flex>
                          </TableCell>
                          <TableCell>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button width="36px" padding="0px" type="ghost">
                                  <MoreHorizontal width="24px" height="24px" />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuPortal>
                                <DropdownMenuContent>
                                  <DropdownMenuLabel>
                                    Piece options
                                  </DropdownMenuLabel>
                                  <DropdownMenuSeparator />
                                  <DropdownMenuItem
                                    onClick={() => {
                                      setPieceToEdit(piece);
                                      setEditPieceModalOpen(true);
                                    }}
                                  >
                                    <Pencil width="16px" height="16px" />
                                    Edit
                                  </DropdownMenuItem>
                                  <DropdownMenuSeparator />
                                  <DropdownMenuItem
                                    onClick={() => {
                                      setPieceToDelete(piece);
                                      setDeletePieceModalOpen(true);
                                    }}
                                  >
                                    <Trash width="16px" height="16px" />
                                    Delete
                                  </DropdownMenuItem>
                                </DropdownMenuContent>
                              </DropdownMenuPortal>
                            </DropdownMenu>
                          </TableCell>
                        </TableRow>
                        {piece.notes && (
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell colSpan={5}>
                              <Flex
                                direction="row"
                                gap="4px"
                                align="left"
                                style={{ padding: "0px 0px 10px 1s0px" }}
                              >
                                <CornerDownRight
                                  size={18}
                                  color={theme.colors.button.primary.background}
                                />
                                <Text color="secondary">{piece.notes}</Text>
                              </Flex>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </DataOverlay>
          <Dialog open={addPieceModalOpen} onOpenChange={setAddPieceModalOpen}>
            <DialogTrigger asChild>
              <Button
                type="outline"
                height="40px"
                padding={"0px"}
                style={{
                  borderStyle: "dashed",
                  borderWidth: 2,
                  textDecoration: "none",
                  borderRadius: "12px",
                }}
              >
                <Flex
                  direction={"row"}
                  gap={"8px"}
                  align="center"
                  justify="center"
                  style={{ margin: "auto" }}
                >
                  <Plus size={30} color={theme.colors.text.secondary} />
                  <Text size="medium" weight="bold" color="secondary">
                    Add piece
                  </Text>
                </Flex>
              </Button>
            </DialogTrigger>
            <DialogContent
              onPointerDownOutside={(e: any) => e.preventDefault()}
            >
              <AddPieceModal
                setAddPieceModalOpen={setAddPieceModalOpen}
                setToastVisible={setToastVisible}
                setToastText={setToastText}
                queryRefetch={piecesResponse.refetch}
                lessonId={lesson}
              />
            </DialogContent>
          </Dialog>
        </Flex>
      </Card>
      <Toast title={toastText} open={toastVisible} />
      <Dialog open={editPieceModalOpen} onOpenChange={setEditPieceModalOpen}>
        <DialogContent onPointerDownOutside={(e: any) => e.preventDefault()}>
          <EditPieceModal
            setEditPieceModalOpen={setEditPieceModalOpen}
            setToastVisible={setToastVisible}
            setToastText={setToastText}
            queryRefetch={piecesResponse.refetch}
            lessonId={lesson}
            piece={pieceToEdit}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={deletePieceModalOpen}
        onOpenChange={setDeletePieceModalOpen}
      >
        <DialogContent onPointerDownOutside={(e: any) => e.preventDefault()}>
          <DeletePieceModal
            setDeletePieceModalOpen={setDeletePieceModalOpen}
            setToastVisible={setToastVisible}
            setToastText={setToastText}
            queryRefetch={piecesResponse.refetch}
            lessonId={lesson}
            piece={pieceToDelete}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PiecesView;
