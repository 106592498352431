import {
  Card,
  CardGrid,
  DataTable,
  DataOverlay,
  Flex,
  Heading,
  ToggleGroup,
  ToggleGroupItem,
  Toast,
  Dialog,
  DialogContent,
  Button,
  Separator,
} from "@tycholabs/armillary";
import { UseUsersList } from "../../../hooks/user";
import { User } from "../../../constants/Types";
import { getStaffColumns } from "./StaffColumns";
import { TablePropertiesIcon, SheetIcon } from "lucide-react";
import { useState } from "react";
import UserCard from "./UserCard";
import DeleteStaffModal from "./DeleteStaffModal";

export const StaffManagement = () => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const permission = sessionStorage.getItem("permission");
  const session = sessionStorage.getItem("session");
  const userResponse = UseUsersList(
    userId ?? "",
    businessId ?? "",
    session ?? ""
  );
  const usersData: User[] = userResponse?.data?.data;

  const [selectedDataView, setSelectedDataView] = useState<string>("table");

  const [deleteStaffModalOpen, setDeleteStaffModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("Saved!");

  return (
    <Flex
      direction="column"
      gap="20px"
      style={{ maxWidth: "82vw", boxSizing: "border-box" }}
    >
      <Flex>
        <Flex direction="column">
          <Heading size="medium">Manage staff</Heading>
          <Heading size="xSmall" color="secondary">
            Communicate with and manage your staff here.
          </Heading>
        </Flex>
      </Flex>

      <DataOverlay
        loading={userResponse.isLoading}
        error={userResponse.isError}
        exists={usersData && usersData.length > 0}
      >
        <Flex direction="row" align="end" justify="right" gap="8px">
          {permission === "1" && (
            <>
              <Button disabled type="primary" width="180px">
                Add staff member
              </Button>
              <Separator orientation="vertical" />
            </>
          )}
          <ToggleGroup
            value={selectedDataView}
            onValueChange={(val: string) => {
              if (val.length > 0) {
                setSelectedDataView(val);
              }
            }}
            type="single"
          >
            <ToggleGroupItem value="table">
              <TablePropertiesIcon />
            </ToggleGroupItem>
            <ToggleGroupItem value="card">
              <SheetIcon />
            </ToggleGroupItem>
          </ToggleGroup>
        </Flex>
        <Card style={{ width: "100%" }}>
          {usersData && (
            <>
              {selectedDataView === "table" ? (
                <DataTable
                  searchFilters={[
                    {
                      value: "firstName",
                      placeholder: "Search first name",
                      width: "200px",
                    },
                    {
                      value: "lastName",
                      placeholder: "Search last name",
                      width: "200px",
                    },
                    {
                      value: "email",
                      placeholder: "Search email",
                      width: "200px",
                    },
                  ]}
                  pagination
                  columns={getStaffColumns(
                    setUserToDelete,
                    setDeleteStaffModalOpen,
                    permission,
                    userId
                  )}
                  columnSelection
                  data={usersData}
                />
              ) : (
                <CardGrid>
                  {usersData.map((user) => (
                    <UserCard user={user} />
                  ))}
                </CardGrid>
              )}
            </>
          )}
        </Card>
      </DataOverlay>
      <Toast title={toastText} open={toastVisible} />
      <Dialog
        open={deleteStaffModalOpen}
        onOpenChange={setDeleteStaffModalOpen}
      >
        <DialogContent onPointerDownOutside={(e: any) => e.preventDefault()}>
          <DeleteStaffModal
            setDeleteStaffModalOpen={setDeleteStaffModalOpen}
            setToastVisible={setToastVisible}
            setToastText={setToastText}
            queryRefetch={userResponse.refetch}
            user={userToDelete}
          />
        </DialogContent>
      </Dialog>
    </Flex>
  );
};

export default StaffManagement;
