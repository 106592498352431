import {
  Flex,
  Text,
  DataOverlay,
  DialogHeader,
  SingleSelect,
  SelectItem,
  TextInput,
  FormField,
  ToggleGroup,
  ToggleGroupItem,
  Checkbox,
  Group,
  Button,
} from "@tycholabs/armillary";
import { UseUsersList } from "../../../hooks/user";
import { Business, Lesson, Location, User } from "../../../constants/Types";
import { Dispatch, SetStateAction, useState } from "react";
import {
  DaysOfTheWeekShort,
  EmailFrequency,
  LessonLengths,
} from "../../../constants";
import { useForm } from "react-hook-form";
import { UseBusinessInfo } from "../../../hooks/business";
import { UsePutUpdateLesson } from "../../../hooks/lesson";

export interface EditLessonModalProps {
  setEditLessonModalOpen: Dispatch<SetStateAction<boolean>>;
  setToastVisible: Dispatch<SetStateAction<boolean>>;
  setToastText: Dispatch<SetStateAction<string>>;
  queryRefetch: any;
  lesson: Lesson;
}

export interface EditLessonFormValues {
  teacherId: string;
  days: string[];
  time: string;
  length: string;
  locationId: string;
  autoSendEmails: boolean;
  emailSchedule: string;
}

export const EditLessonModal = ({
  setEditLessonModalOpen,
  setToastVisible,
  setToastText,
  queryRefetch,
  lesson,
}: EditLessonModalProps) => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const permission = sessionStorage.getItem("permission");
  const session = sessionStorage.getItem("session");
  const userResponse = UseUsersList(
    userId ?? "",
    businessId ?? "",
    session ?? ""
  );
  const usersData: User[] = userResponse?.data?.data;
  const businessResponse = UseBusinessInfo(
    userId ?? "",
    businessId ?? "",
    session ?? ""
  );
  const businessData: Business = businessResponse?.data?.data[0];

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<EditLessonFormValues>({
    defaultValues: {
      teacherId: `${lesson.teacher.id}` ?? undefined,
      days: lesson ? lesson.days.split("") : undefined,
      time: lesson ? lesson.time : undefined,
      length: lesson ? `${lesson.length}` : undefined,
      locationId: lesson ? `${lesson.location.id}` : undefined,
      autoSendEmails: lesson ? lesson.autoSendEmails : true,
      emailSchedule: lesson ? `${lesson.emailScheduleId}` : undefined,
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const updateLesson = UsePutUpdateLesson(
    userId ?? "",
    businessId ?? "",
    {
      id: lesson.id,
      teacherId: getValues("teacherId"),
      days:
        getValues("days") && getValues("days").length > 0
          ? getValues("days").sort().join("")
          : "",
      time: getValues("time"),
      length: getValues("length"),
      locationId: getValues("locationId"),
      autoSendEmails: getValues("autoSendEmails"),
      emailScheduleId: getValues("autoSendEmails")
        ? getValues("emailSchedule")
        : "1",
    },
    session ?? ""
  );

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    updateLesson.mutateAsync().then((res) => {
      if (res.data === "Success") {
        queryRefetch();
        setEditLessonModalOpen(false);
        setToastText("Lesson updated!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <DialogHeader
        title={`Edit ${lesson.student.name}'s ${lesson.instrument} lesson`}
      />
      <form
        noValidate
        onSubmit={handleSubmit(() => {
          try {
            handleSubmitButtonClick();
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <Flex direction="column" gap="30px">
          <Flex direction="column" gap="30px" width="60vw">
            <DataOverlay
              loading={userResponse.isLoading || businessResponse.isLoading}
              error={userResponse.isError || businessResponse.isError}
              exists={
                usersData && usersData.length > 0 && businessData !== null
              }
            >
              <Group label="Details" level="secondary">
                {usersData && (
                  <Flex direction="column" gap="8px">
                    <FormField
                      label="Teacher"
                      error={errors && errors.teacherId?.message}
                      required
                    >
                      <SingleSelect
                        disabled={permission !== "1"}
                        placeholder="Select a teacher"
                        defaultValue={getValues("teacherId")}
                        onValueChange={(val: string) =>
                          setValue(`teacherId`, val)
                        }
                        {...register(`teacherId`, {
                          required: "Please select a teacher.",
                        })}
                      >
                        {usersData.map((teacher: User) => {
                          return (
                            <SelectItem value={`${teacher.id}`}>
                              {teacher.firstName} {teacher.lastName}
                            </SelectItem>
                          );
                        })}
                      </SingleSelect>
                    </FormField>
                  </Flex>
                )}
              </Group>

              <Flex direction="column" gap="30px">
                <Group label="Schedule" level="secondary">
                  <FormField
                    label="Lesson day(s)"
                    error={errors && errors.days?.message}
                    required
                  >
                    <ToggleGroup
                      type="multiple"
                      value={watch(`days`)}
                      onValueChange={(vals: string[]) => {
                        if (vals.length > 0) {
                          setValue(`days`, vals);
                        }
                      }}
                      {...register(`days`, {
                        required: "Please select an available day of the week.",
                      })}
                    >
                      {[...Array(7)].map((v, i) => {
                        return (
                          <ToggleGroupItem value={`${i}`}>
                            {DaysOfTheWeekShort[i]}
                          </ToggleGroupItem>
                        );
                      })}
                    </ToggleGroup>
                  </FormField>

                  <FormField
                    label="Lesson time"
                    error={errors && errors.time?.message}
                    required
                  >
                    <TextInput
                      type="time"
                      value={watch(`time`)}
                      {...register(`time`, {
                        required: "Please enter a valid time.",
                      })}
                    />
                  </FormField>

                  <FormField
                    label="Lesson length (in minutes)"
                    error={errors && errors.length?.message}
                    required
                  >
                    <ToggleGroup
                      type="single"
                      value={watch(`length`)}
                      onValueChange={(val: string) => {
                        if (val.length > 0) {
                          setValue(`length`, val);
                        }
                      }}
                      {...register(`length`, {
                        required: "Please select a lesson length.",
                      })}
                    >
                      {LessonLengths.map((v, i) => {
                        return (
                          <ToggleGroupItem value={`${v}`}>{v}</ToggleGroupItem>
                        );
                      })}
                    </ToggleGroup>
                  </FormField>
                  {businessData && (
                    <FormField
                      label="Location"
                      error={errors && errors.locationId?.message}
                      required
                    >
                      <SingleSelect
                        placeholder="Select a location"
                        defaultValue={getValues("locationId")}
                        onValueChange={(val: string) =>
                          setValue(`locationId`, val)
                        }
                        {...register(`locationId`, {
                          required: "Please select a location.",
                        })}
                      >
                        {businessData.locations.map((location: Location) => {
                          return (
                            <SelectItem value={`${location.id}`}>
                              {location.name}
                            </SelectItem>
                          );
                        })}
                      </SingleSelect>
                    </FormField>
                  )}
                </Group>

                <Group label="Email options" level="secondary">
                  <Flex direction="row" gap="8px" align="center">
                    <Checkbox
                      checked={watch("autoSendEmails")}
                      onCheckedChange={(val: boolean) =>
                        setValue("autoSendEmails", val)
                      }
                    />
                    <Text color="secondary">Auto-send emails</Text>
                  </Flex>

                  <FormField
                    label="Email frequency"
                    error={errors && errors.emailSchedule?.message}
                    required={watch("autoSendEmails")}
                  >
                    <ToggleGroup
                      disabled={!watch("autoSendEmails")}
                      type="single"
                      value={watch(`emailSchedule`)}
                      onValueChange={(val: string) => {
                        if (val.length > 0) {
                          setValue(`emailSchedule`, val);
                        }
                      }}
                      {...register(`emailSchedule`, {
                        required: {
                          value: watch("autoSendEmails"),
                          message: "Please select an email frequency.",
                        },
                      })}
                    >
                      {EmailFrequency.map((v, i) => {
                        return (
                          <ToggleGroupItem value={`${i + 1}`}>
                            {v}
                          </ToggleGroupItem>
                        );
                      })}
                    </ToggleGroup>
                  </FormField>
                </Group>
              </Flex>
            </DataOverlay>
          </Flex>
          <Button
            type={"primary"}
            width={"100px"}
            loading={isSubmitting}
            onClick={() => console.log("Submitted!")}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </>
  );
};

export default EditLessonModal;
