import {
  Button,
  DataTableColumnHeader,
  Text,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuItem,
  Flex,
} from "@tycholabs/armillary";
import { MoreHorizontal, Trash } from "lucide-react";
import { Link } from "react-router-dom";
import { Instrument, User } from "../../../constants/Types";
import { Dispatch, SetStateAction } from "react";

export const getStaffColumns = (
  setUserToDelete: Dispatch<SetStateAction<User | null>>,
  setDeleteStaffModalOpen: Dispatch<SetStateAction<boolean>>,
  permission: string | null,
  currentUser: string | null
) => {
  return [
    {
      accessorKey: "firstName",
      meta: "First name",
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="First name" />
      ),
    },
    {
      accessorKey: "lastName",
      meta: "Last name",
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Last name" />
      ),
    },
    {
      accessorKey: "email",
      meta: "Email",
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Email" />
      ),
    },
    {
      id: "lessons",
      accessorKey: "lessons",
      meta: "Instrument",
      enableSorting: false,
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Instrument" />
      ),
      cell: ({ row }: any) => {
        return row.original.instruments.map(
          (instrument: Instrument, i: number) => (
            <>
              {instrument.name}
              {i === row.original.instruments.length - 1 ? "" : ", "}
            </>
          )
        );
      },
    },
    {
      accessorKey: "groupId",
      meta: "Role",
      enableSorting: true,
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Role" />
      ),
      cell: ({ row }: any) => {
        return (
          <Text>
            {row.original.groupId === 1 ? "Administrator" : "Teacher"}
          </Text>
        );
      },
    },
    {
      id: "actions",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Options" justify="left" />
      ),
      cell: ({ row }: any) => {
        return (
          <Flex direction="row" gap="4px">
            <Link
              style={{ textDecoration: "none" }}
              to={`/staff/${row.original.id}`}
            >
              <Button type="outline" width="auto">
                View
              </Button>
            </Link>

            {permission === "1" && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button width="36px" padding="0px" type="ghost">
                    <MoreHorizontal width="24px" height="24px" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuContent>
                    <DropdownMenuLabel>Staff options</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    {permission === "1" &&
                      currentUser !== `${row.original.id}` && (
                        <>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem
                            onClick={() => {
                              setUserToDelete(row.original);
                              setDeleteStaffModalOpen(true);
                            }}
                          >
                            <Trash width="16px" height="16px" />
                            Delete
                          </DropdownMenuItem>
                        </>
                      )}
                  </DropdownMenuContent>
                </DropdownMenuPortal>
              </DropdownMenu>
            )}
          </Flex>
        );
      },
    },
  ];
};
