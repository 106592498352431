import {
  Flex,
  Heading,
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "@tycholabs/armillary";
import StudentManagement from "./StudentManagement/StudentManagement";
import LessonManagement from "./LessonManagement/LessonManagement";
import { Link, useLocation } from "react-router-dom";

export const Students = () => {
  const location = useLocation();
  return (
    <Flex
      direction="column"
      gap="20px"
      style={{ maxWidth: "82vw", boxSizing: "border-box" }}
    >
      <Flex direction="column">
        <Heading size="medium">Student management</Heading>
        <Heading size="xSmall" color="secondary">
          Manage your students and their lessons.
        </Heading>
      </Flex>
      {console.log(location.pathname.split("/")[2])}
      <Tabs
        defaultValue={
          location.pathname.split("/")[2] === "studentlist"
            ? "studentlist"
            : "lessons"
        }
      >
        <TabsList>
          <Link
            style={{
              textDecoration: "none",
            }}
            to="/students/lessons"
          >
            <TabsTrigger value="lessons">Lessons</TabsTrigger>
          </Link>
          <Link
            style={{
              textDecoration: "none",
            }}
            to="/students/studentlist"
          >
            <TabsTrigger value="studentlist">Students</TabsTrigger>
          </Link>
        </TabsList>
        <TabsContent value="lessons">
          <LessonManagement />
        </TabsContent>
        <TabsContent value="studentlist">
          <StudentManagement />
        </TabsContent>
      </Tabs>
    </Flex>
  );
};

export default Students;
