import axios from "axios";
import { endpoint } from "../constants/Endpoints";
import { PostLoginDTO, PostLogoutDTO } from "../constants/DTO";
import { useMutation } from "@tanstack/react-query";

export const PostLogin = async (auth: PostLoginDTO) => {
  return await axios
    .post(`${endpoint}/login`, {
      userEmail: auth.userEmail,
      password: auth.password,
    })
    .then((res) => {
      return res;
    });
};

export const UsePostLogin = (auth: PostLoginDTO) => {
  const response = useMutation({
    mutationKey: [`${auth.userEmail}-PostLogin`],
    mutationFn: () => PostLogin(auth),
  });
  return response;
};

export const PostLogout = async (auth: PostLogoutDTO) => {
  return await axios
    .post(`${endpoint}/logout`, {
      user: auth.userId,
      session: auth.session,
    })
    .then((res) => {
      return res;
    });
};

export const UsePostLogout = (auth: PostLogoutDTO) => {
  const response = useMutation({
    mutationKey: [`${auth.userId}-PostLogout`],
    mutationFn: () => PostLogout(auth),
  });
  return response;
};
