import { Flex, DialogHeader, Button, Text } from "@tycholabs/armillary";
import { Dispatch, SetStateAction, useState } from "react";
import { Contact } from "../../../constants/Types";
import { UseDeleteContact } from "../../../hooks/student";

export interface DeleteContactModalProps {
  setDeleteContactModalOpen: Dispatch<SetStateAction<boolean>>;
  setToastVisible: Dispatch<SetStateAction<boolean>>;
  setToastText: Dispatch<SetStateAction<string>>;
  queryRefetch: any;
  studentId: string;
  contact: Contact | null;
}

export const DeleteContactModal = ({
  setDeleteContactModalOpen,
  setToastVisible,
  setToastText,
  queryRefetch,
  studentId,
  contact,
}: DeleteContactModalProps) => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const deleteContact = UseDeleteContact(
    userId ?? "",
    businessId ?? "",
    {
      id: contact ? contact.id : "",
      studentId: studentId,
      email: contact ? contact.email : "",
      phone: contact ? contact.phone : "",
    },
    session ?? ""
  );

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    deleteContact.mutateAsync().then((res) => {
      if (res.data === "Success") {
        queryRefetch();
        setDeleteContactModalOpen(false);
        setToastText("Contact removed!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <DialogHeader title={"Remove Contact?"} />
      <Text>This action cannot be undone.</Text>
      <Flex direction="row" gap="30px">
        <Button
          type={"secondary"}
          width={"100px"}
          onClick={() => setDeleteContactModalOpen(false)}
        >
          Cancel
        </Button>
        <Button
          type={"destructive"}
          width={"100px"}
          loading={isSubmitting}
          onClick={() => handleSubmitButtonClick()}
        >
          Delete
        </Button>
      </Flex>
    </>
  );
};

export default DeleteContactModal;
