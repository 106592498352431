import {
  Flex,
  Heading,
  Card,
  FormField,
  Button,
  OTPInput,
  OTPInputGroup,
  OTPInputSlot,
} from "@tycholabs/armillary";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import { UsePostValidateCode } from "../../hooks/reset";

export interface ResetPasswordCodeProps {
  resetEmail: string;
  setResetCode: Dispatch<SetStateAction<string | null>>;
  setResetKey: Dispatch<SetStateAction<string | null>>;
}

export interface ResetFormValues {
  resetCode: string;
}

export const ResetPasswordCode = ({
  resetEmail,
  setResetCode,
  setResetKey,
}: ResetPasswordCodeProps) => {
  const theme = useTheme();

  const [codeErrorVisible, setCodeErrorVisible] = useState<boolean>(false);

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetFormValues>({
    defaultValues: {
      resetCode: "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const validateCode = UsePostValidateCode({
    resetEmail: resetEmail,
    resetCode: getValues("resetCode"),
  });

  const handleSendCodeClick = () => {
    setIsSubmitting(true);
    setCodeErrorVisible(false);
    validateCode.mutateAsync().then((res) => {
      if (res.data.key) {
        setResetCode(getValues("resetCode"));
        setResetKey(res.data.key);
      } else {
        setIsSubmitting(false);
        setCodeErrorVisible(true);
      }
    });
  };

  return (
    <Flex direction="row" justify="center" align="center">
      <Card style={{ width: "50%", minWidth: "400px" }}>
        <Heading>Reset Password</Heading>
        <form
          noValidate
          onSubmit={handleSubmit(() => {
            try {
              handleSendCodeClick();
            } catch (e) {
              console.log(e);
            }
          })}
        >
          <Flex direction="column" gap="8px" align="center">
            <FormField
              required
              label="Enter code"
              width="auto"
              error={codeErrorVisible ? "Invalid code entered." : undefined}
            >
              <OTPInput
                maxLength={6}
                value={watch("resetCode") ?? ""}
                onChange={(val: string) => setValue("resetCode", val)}
              >
                <OTPInputGroup>
                  <OTPInputSlot index={0} />
                  <OTPInputSlot index={1} />
                  <OTPInputSlot index={2} />
                  <OTPInputSlot index={3} />
                  <OTPInputSlot index={4} />
                  <OTPInputSlot index={5} />
                </OTPInputGroup>
              </OTPInput>
            </FormField>

            <Button
              disabled={watch("resetCode").length !== 6}
              width="auto"
              type="primary"
              loading={isSubmitting}
              onClick={() => console.log("Submitted!")}
            >
              Verify Code
            </Button>
          </Flex>
        </form>
      </Card>
    </Flex>
  );
};

export default ResetPasswordCode;
