import { Page, NavMenu, NavMenuButtonRow, Flex } from "@tycholabs/armillary";
import { Content, ContentMain } from "@tycholabs/armillary";
import { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import ResetPasswordEmail from "./ResetPasswordEmail";
import ResetPasswordCode from "./ResetPasswordCode";
import ResetPasswordEnter from "./ResetPasswordEnter";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import { UseMetadata } from "../../hooks/metadata";
import { Metadata } from "../../constants/Types";
import { useNavigate } from "react-router-dom";
import packageJson from "../../../package.json";
import { Loader } from "../../components/Loader";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [resetEmail, setResetEmail] = useState<string | null>(null);
  const [resetCode, setResetCode] = useState<string | null>(null);
  const [resetKey, setResetKey] = useState<string | null>(null);
  const [passSuccess, setPassSuccess] = useState<boolean>(false);

  const metadataResponse = UseMetadata();
  const metadata: Metadata = metadataResponse?.data?.data;

  useEffect(() => {
    if (metadataResponse.isError) {
      navigate("/maintenance");
    }
    if (metadata) {
      console.log(metadata);
      if (metadata.statusActive === false) {
        navigate("/maintenance");
      }
      if (metadata.version === packageJson.version) {
        localStorage.setItem("banner", metadata.banner ?? "");
        localStorage.setItem(
          "bannerActive",
          `${metadata.bannerActive}` ?? "false"
        );
        localStorage.setItem("status", metadata.status ?? "");
        localStorage.setItem(
          "statusActive",
          `${metadata.statusActive}` ?? "false"
        );
        localStorage.setItem("version", metadata.version);
      } else {
        window.location.reload();
      }
    }
  }, [metadataResponse, metadata]);

  return metadataResponse.isLoading ? (
    <Flex direction="column" gap="0" style={{ height: "100vh" }}>
      <Page direction="row">
        <Content>
          <ContentMain scrollY="scroll">
            <Flex
              style={{ height: "100%" }}
              direction="row"
              justify="center"
              align="center"
            >
              <Loader size={60} />
            </Flex>
          </ContentMain>
        </Content>
      </Page>
    </Flex>
  ) : (
    <Flex direction="column" gap="0" style={{ height: "100vh" }}>
      <div style={{ borderBottom: `1px solid ${theme.colors.layout.border}` }}>
        <NavMenu title="Chordia BETA">
          <NavMenuButtonRow></NavMenuButtonRow>
        </NavMenu>
      </div>
      <Page direction="row">
        <Content>
          <ContentMain scrollY="scroll">
            {passSuccess ? (
              <ResetPasswordSuccess />
            ) : (
              <>
                {resetKey && resetCode && resetEmail && (
                  <ResetPasswordEnter
                    resetEmail={resetEmail}
                    resetCode={resetCode}
                    resetKey={resetKey}
                    setPassSuccess={setPassSuccess}
                  />
                )}
                {resetEmail && !resetCode && !resetKey && (
                  <ResetPasswordCode
                    resetEmail={resetEmail}
                    setResetCode={setResetCode}
                    setResetKey={setResetKey}
                  />
                )}
                {!resetEmail && !resetCode && !resetKey && (
                  <ResetPasswordEmail setResetEmail={setResetEmail} />
                )}
              </>
            )}
          </ContentMain>
        </Content>
      </Page>
    </Flex>
  );
};

export default ResetPassword;
