import { Music, MapPin, CircleUser, Calendar } from "lucide-react";
import { Link } from "react-router-dom";
import { Lesson } from "../../../constants/Types";
import { Button, Flex, Heading, Text } from "@tycholabs/armillary";
import { convertTo12Hr, getDaysList } from "../../../utils";
import { useTheme } from "styled-components";

export interface StudentLessonCardProps {
  lesson: Lesson;
}

export const StudentLessonCard = ({ lesson }: StudentLessonCardProps) => {
  const theme = useTheme();
  return (
    <Link
      style={{ textDecoration: "none", alignItems: "left" }}
      to={`/students/lesson/${lesson.id}`}
    >
      <Button
        type="outline"
        height="100%"
        style={{
          textDecoration: "none",
          width: "24%",
          minWidth: "250px",
          maxWidth: "24%",
          height: "180px",
          borderRadius: "12px",
        }}
        padding={"30px"}
      >
        <Flex direction="column" gap="8px" align="left">
          <Flex direction="row" justify="space-between" align="center">
            <Heading>{lesson.instrument}</Heading>
            <Music color={theme.colors.text.secondary} size={18} />
          </Flex>

          <Flex direction="row" gap="8px" align="left">
            <MapPin size={18} color={theme.colors.text.secondary} />
            <Text size="small" color="secondary">
              {lesson.location.name}
            </Text>
          </Flex>
          <Flex direction="row" gap="8px" align="left">
            <Calendar size={18} color={theme.colors.text.secondary} />
            <Text size="small" color="secondary">
              {getDaysList(lesson.days.split(""), true)},{" "}
              {convertTo12Hr(lesson.time)}
            </Text>
          </Flex>
          <Flex direction="row" gap="8px" align="left">
            <CircleUser size={18} color={theme.colors.text.secondary} />
            <Text size="small" color="secondary">
              {lesson.teacher.name}
            </Text>
          </Flex>
        </Flex>
      </Button>
    </Link>
  );
};

export default StudentLessonCard;
