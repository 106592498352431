import { Flex, Heading, Card, DataOverlay } from "@tycholabs/armillary";

export const Settings = () => {
  return (
    <Flex
      direction="column"
      gap="20px"
      style={{ maxWidth: "82vw", boxSizing: "border-box" }}
    >
      <Flex direction="column">
        <Heading size="medium">Settings</Heading>
        <Heading size="xSmall" color="secondary">
          Change your settings here.
        </Heading>
      </Flex>
      <Card style={{ width: "100%" }}>
        <DataOverlay exists={false}>
          <></>
        </DataOverlay>
      </Card>
    </Flex>
  );
};

export default Settings;
