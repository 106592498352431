import {
  Flex,
  Text,
  DialogHeader,
  TextInput,
  FormField,
  Checkbox,
  Group,
  Button,
} from "@tycholabs/armillary";
import { Dispatch, SetStateAction, useState } from "react";
import { emailRegex } from "../../../constants";
import { useForm } from "react-hook-form";
import { UsePostNewContact } from "../../../hooks/student";

export interface AddContactModalProps {
  setAddContactModalOpen: Dispatch<SetStateAction<boolean>>;
  setToastVisible: Dispatch<SetStateAction<boolean>>;
  setToastText: Dispatch<SetStateAction<string>>;
  queryRefetch: any;
  studentId: string;
}

export interface AddContactFormValues {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  isPrimary: boolean;
  emailOptIn: boolean;
  notes: string;
}

export const AddContactModal = ({
  setAddContactModalOpen,
  setToastVisible,
  setToastText,
  queryRefetch,
  studentId,
}: AddContactModalProps) => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AddContactFormValues>({
    defaultValues: {
      email: undefined,
      phone: undefined,
      firstName: undefined,
      lastName: undefined,
      isPrimary: false,
      emailOptIn: true,
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const postContact = UsePostNewContact(
    userId ?? "",
    businessId ?? "",
    {
      studentId: studentId,
      email: getValues("email"),
      phone: getValues("phone"),
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      isPrimary: getValues("isPrimary"),
      emailOptIn: getValues("emailOptIn"),
      notes: getValues("notes"),
    },
    session ?? ""
  );

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    postContact.mutateAsync().then((res) => {
      if (res.data === "Success") {
        queryRefetch();
        setAddContactModalOpen(false);
        setToastText("Contact added!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <DialogHeader title={"Add Contact"} />
      <form
        noValidate
        onSubmit={handleSubmit(() => {
          try {
            handleSubmitButtonClick();
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <Flex direction="column" gap="30px">
          <Flex direction="column" gap="30px" style={{ minWidth: "40vw" }}>
            <Group label="Details" level="secondary">
              <Flex direction="column" gap="8px">
                <FormField
                  label="Email"
                  error={errors && errors.email?.message}
                  required
                >
                  <TextInput
                    type="email"
                    required
                    value={watch(`email`)}
                    {...register("email", {
                      required: "Please enter an email.",
                      pattern: {
                        value: emailRegex,
                        message: "Please enter a valid email.",
                      },
                    })}
                  />
                </FormField>

                <FormField
                  label="Phone number"
                  error={errors && errors.phone?.message}
                  required
                >
                  <TextInput
                    type="tel"
                    value={watch(`phone`)}
                    {...register(`phone`, {
                      minLength: {
                        value: 10,
                        message: "Please enter a valid phone number",
                      },
                      maxLength: {
                        value: 10,
                        message: "Please enter a valid phone number",
                      },
                      required: "Please enter a valid phone number.",
                    })}
                  />
                </FormField>

                <FormField
                  label="First name"
                  error={errors && errors.firstName?.message}
                  required
                >
                  <TextInput
                    value={watch(`firstName`)}
                    {...register(`firstName`, {
                      required: "Please enter a first name.",
                    })}
                  />
                </FormField>
                <FormField
                  label="Last name"
                  error={errors && errors.lastName?.message}
                  required
                >
                  <TextInput
                    value={watch(`lastName`)}
                    {...register(`lastName`, {
                      required: "Please enter a last name.",
                    })}
                  />
                </FormField>

                <FormField
                  label="Notes"
                  error={errors && errors.notes?.message}
                >
                  <TextInput
                    value={watch(`notes`)}
                    {...register(`notes`, {
                      maxLength: {
                        value: 140,
                        message: "Please enter up to 140 characters.",
                      },
                    })}
                  />
                </FormField>
              </Flex>
            </Group>

            <Group label="Email options" level="secondary">
              <Flex direction="row" gap="8px" align="center">
                <Checkbox
                  checked={watch("isPrimary")}
                  onCheckedChange={(val: boolean) => setValue("isPrimary", val)}
                />
                <Text color="secondary">Primary contact</Text>
              </Flex>

              <Flex direction="row" gap="8px" align="center">
                <Checkbox
                  checked={watch("emailOptIn")}
                  onCheckedChange={(val: boolean) =>
                    setValue("emailOptIn", val)
                  }
                />
                <Text color="secondary">Opt into email communication</Text>
              </Flex>
            </Group>
          </Flex>
          <Button
            type={"primary"}
            width={"100px"}
            loading={isSubmitting}
            onClick={() => console.log("Submitted!")}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </>
  );
};

export default AddContactModal;
