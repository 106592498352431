import {
  Flex,
  Heading,
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
  DataOverlay,
} from "@tycholabs/armillary";
import LocationManagement from "./LocationManagement/LocationManagement";

export const Business = () => {
  return (
    <Flex
      direction="column"
      gap="20px"
      style={{ maxWidth: "82vw", boxSizing: "border-box" }}
    >
      <Flex direction="column">
        <Heading size="medium">Business</Heading>
        <Heading size="xSmall" color="secondary">
          Manage your business and location info.
        </Heading>
      </Flex>
      <Tabs defaultValue="locations">
        <TabsList>
          <TabsTrigger value="locations">Locations</TabsTrigger>
          <TabsTrigger disabled value="details">
            Details
          </TabsTrigger>
        </TabsList>
        <TabsContent value="locations">
          <LocationManagement />
        </TabsContent>
        <TabsContent value="details">
          <DataOverlay exists={false}>Nothing!</DataOverlay>
        </TabsContent>
      </Tabs>
    </Flex>
  );
};

export default Business;
