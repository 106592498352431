import axios from "axios";
import { endpoint } from "../constants/Endpoints";
import { ReportBugDTO } from "../constants/DTO";
import { useMutation } from "@tanstack/react-query";

const PostNewBugReport = async (
  userId: string,
  businessId: string,
  report: ReportBugDTO,
  sessionId: string
) => {
  return await axios
    .post(
      `${endpoint}/report`,
      {
        report,
      },
      {
        params: {
          user: userId,
          business: businessId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePostNewBugReport = (
  userId: string,
  businessId: string,
  report: ReportBugDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PostNewBugReport`],
    mutationFn: () => PostNewBugReport(userId, businessId, report, sessionId),
  });
  return response;
};
