import {
  Card,
  CardGrid,
  DataOverlay,
  Flex,
  Heading,
  Button,
  Text,
  Dialog,
  DialogTrigger,
  DialogContent,
  Toast,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  colorValues,
} from "@tycholabs/armillary";
import { MoreHorizontal, Pencil, Plus, Star, Trash } from "lucide-react";
import { useState } from "react";
import { UseBusinessInfo } from "../../../hooks/business";
import { Business, Location } from "../../../constants/Types";
import LocationCard from "./LocationCard";
import EditLocationModal from "./EditLocationModal";
import AddLocationModal from "./AddLocationModal";
import { useTheme } from "styled-components";

export const BusinessManagement = () => {
  const theme = useTheme();
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const permission = sessionStorage.getItem("permission");
  const session = sessionStorage.getItem("session");

  const businessResponse = UseBusinessInfo(
    userId ?? "",
    businessId ?? "",
    session ?? ""
  );
  const businessData: Business = businessResponse?.data?.data[0];

  const [viewLocationModalOpen, setViewLocationModalOpen] = useState(false);
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false);
  const [editLocationModalOpen, setEditLocationModalOpen] = useState(false);
  const [deleteLocationModalOpen, setDeleteLocationModalOpen] = useState(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("Saved!");

  const [locationToView, setLocationToView] = useState<Location | null>(null);
  const [locationToEdit, setLocationToEdit] = useState<Location | null>(null);
  const [locationToDelete, setLocationToDelete] = useState<Location | null>(
    null
  );

  return (
    <>
      <Flex direction="row" gap="30px">
        <Card style={{ width: "100%" }}>
          <Flex direction="column">
            <Heading size="medium">View Locations</Heading>
            <Heading size="xSmall" color="secondary">
              Add or edit upcoming Locations here.
            </Heading>
          </Flex>
          <DataOverlay
            loading={businessResponse.isLoading}
            error={businessResponse.isError}
            exists={businessData && businessData !== null}
          >
            {businessData && (
              <CardGrid>
                {businessData.locations.map((location) => (
                  <Flex
                    width="24%"
                    style={{
                      minWidth: "250px",
                      maxWidth: "24%",
                      height: "160px",
                      position: "relative",
                    }}
                  >
                    <Button
                      type="outline"
                      height="100%"
                      disabled
                      style={{
                        pointerEvents: "none",
                        textDecoration: "none",
                        borderRadius: "12px",
                        boxShadow: theme.colors.shadow.small,
                      }}
                      padding={"30px"}
                      onClick={() => {
                        setLocationToView(location);
                        setViewLocationModalOpen(true);
                      }}
                    >
                      <LocationCard location={location} />
                    </Button>
                    {location.primary && (
                      <Star
                        size={20}
                        color={colorValues.TychoGoldDark}
                        style={{ position: "absolute", top: 10, right: 10 }}
                      />
                    )}
                    {permission === "1" && (
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            style={{
                              position: "absolute",
                              zIndex: 50,
                              bottom: 5,
                              right: 5,
                            }}
                            width="36px"
                            height="36px"
                            padding="0px"
                            type="ghost"
                          >
                            <MoreHorizontal width="24px" height="24px" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuPortal>
                          <DropdownMenuContent>
                            <DropdownMenuLabel>
                              Location options
                            </DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              onClick={() => {
                                setLocationToEdit(location);
                                setEditLocationModalOpen(true);
                              }}
                            >
                              <Pencil width="16px" height="16px" />
                              Edit
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              onClick={() => {
                                setLocationToDelete(location);
                                setDeleteLocationModalOpen(true);
                              }}
                            >
                              <Trash width="16px" height="16px" />
                              Delete
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenuPortal>
                      </DropdownMenu>
                    )}
                  </Flex>
                ))}
                {permission === "1" && (
                  <Dialog
                    open={addLocationModalOpen}
                    onOpenChange={setAddLocationModalOpen}
                  >
                    <DialogTrigger asChild>
                      <Button
                        type="outline"
                        height="100%"
                        padding={"30px"}
                        style={{
                          borderStyle: "dashed",
                          borderWidth: "2px",
                          textDecoration: "none",
                          width: "24%",
                          minWidth: "250px",
                          maxWidth: "24%",
                          height: "160px",
                          borderRadius: "12px",
                          boxShadow: theme.colors.shadow.small,
                        }}
                      >
                        <Flex
                          direction={"row"}
                          gap={"8px"}
                          align="center"
                          style={{ margin: "auto" }}
                        >
                          <Plus
                            width={"36px"}
                            height={"36px"}
                            color={theme.colors.text.secondary}
                          />
                          <Text size="xLarge" weight="bold" color="secondary">
                            Add Location
                          </Text>
                        </Flex>
                      </Button>
                    </DialogTrigger>
                    <DialogContent
                      onPointerDownOutside={(e: any) => e.preventDefault()}
                    >
                      <AddLocationModal
                        setAddLocationModalOpen={setAddLocationModalOpen}
                        setToastVisible={setToastVisible}
                        setToastText={setToastText}
                        queryRefetch={businessResponse.refetch}
                      />
                    </DialogContent>
                  </Dialog>
                )}
              </CardGrid>
            )}
          </DataOverlay>
        </Card>
        <Dialog
          open={editLocationModalOpen}
          onOpenChange={setEditLocationModalOpen}
        >
          <DialogContent onPointerDownOutside={(e: any) => e.preventDefault()}>
            <EditLocationModal
              setEditLocationModalOpen={setEditLocationModalOpen}
              setToastVisible={setToastVisible}
              setToastText={setToastText}
              queryRefetch={businessResponse.refetch}
              location={locationToEdit}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={deleteLocationModalOpen}
          onOpenChange={setDeleteLocationModalOpen}
        >
          <DialogContent>
            <Text>Working on it!</Text>
            {/* <DeleteLocationModal
            setDeleteLocationModalOpen={setDeleteLocationModalOpen}
            setToastVisible={setToastVisible}
            setToastText={setToastText}
            queryRefetch={LocationResponse.refetch}
            Location={LocationToDelete}
          /> */}
          </DialogContent>
        </Dialog>
      </Flex>
      <Toast title={toastText} open={toastVisible} />
    </>
  );
};

export default BusinessManagement;
