import { ArmillaryColorTheme } from "@tycholabs/armillary";

export const bayTheme: ArmillaryColorTheme = {
  light: {
    primary: "#1a5dcd",
    secondary: "#ccd6e7",
    background: "#edf1f8",
    subdued: "#9cafe6",
  },
  dark: {
    primary: "#1a5dcd",
    secondary: "#172345",
    background: "#060b18",
    subdued: "#0b183f",
  },
};

export const themeChordia: ArmillaryColorTheme = {
  light: {
    primary: "#25da8d",
    secondary: "#dae3df",
    background: "#fafcfc",
    subdued: "#a0cdba",
  },
  dark: {
    primary: "#35a978",
    secondary: "#363231",
    background: "#1c1917",
    subdued: "#1c593f",
  },
};
