import axios from "axios";
import { endpoint } from "../constants/Endpoints";
import {
  ResetPassEmailDTO,
  ResetPassEmailCodeDTO,
  ResetPassEmailCodeKeyPassDTO,
} from "../constants/DTO";
import { useMutation } from "@tanstack/react-query";

const PostInitReset = async (info: ResetPassEmailDTO) => {
  return await axios
    .post(`${endpoint}/reset`, {
      resetEmail: info.resetEmail,
    })
    .then((res) => {
      return res;
    });
};

export const UsePostInitReset = (info: ResetPassEmailDTO) => {
  const response = useMutation({
    mutationKey: [`${info.resetEmail}-PostInitReset`],
    mutationFn: () => PostInitReset(info),
  });
  return response;
};

const PostValidateCode = async (info: ResetPassEmailCodeDTO) => {
  return await axios
    .post(`${endpoint}/reset/validate`, {
      resetEmail: info.resetEmail,
      resetCode: info.resetCode,
    })
    .then((res) => {
      return res;
    });
};

export const UsePostValidateCode = (info: ResetPassEmailCodeDTO) => {
  const response = useMutation({
    mutationKey: [`${info.resetEmail}-${info.resetCode}-PostValidateCode`],
    mutationFn: () => PostValidateCode(info),
  });
  return response;
};

const PostResetPass = async (info: ResetPassEmailCodeKeyPassDTO) => {
  return await axios
    .post(`${endpoint}/reset/new`, {
      resetEmail: info.resetEmail,
      resetCode: info.resetCode,
      resetKey: info.resetKey,
      resetPass: info.resetPass,
    })
    .then((res) => {
      return res;
    });
};

export const UsePostResetPass = (info: ResetPassEmailCodeKeyPassDTO) => {
  const response = useMutation({
    mutationKey: [`${info.resetEmail}-${info.resetCode}-PostResetPass`],
    mutationFn: () => PostResetPass(info),
  });
  return response;
};
