import {
  Flex,
  Text,
  DialogHeader,
  TextInput,
  FormField,
  Button,
  Textarea,
} from "@tycholabs/armillary";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { UsePostNewBugReport } from "../../hooks/report";

export interface ReportBugModalProps {
  setReportBugModalOpen: Dispatch<SetStateAction<boolean>>;
  setToastVisible: Dispatch<SetStateAction<boolean>>;
  setToastText: Dispatch<SetStateAction<string>>;
}

export interface ReportBugFormValues {
  page: string;
  bugDetails: string;
  stepsToReplicate: string;
}

export const ReportBugModal = ({
  setReportBugModalOpen,
  setToastVisible,
  setToastText,
}: ReportBugModalProps) => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ReportBugFormValues>({
    defaultValues: {
      page: undefined,
      bugDetails: undefined,
      stepsToReplicate: undefined,
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const bugReport = UsePostNewBugReport(
    userId ?? "",
    businessId ?? "",
    {
      page: getValues("page"),
      bugDetails: getValues("bugDetails"),
      stepsToReplicate: getValues("stepsToReplicate"),
    },
    session ?? ""
  );

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    bugReport.mutateAsync().then((res) => {
      if (res.data === "Success") {
        setReportBugModalOpen(false);
        setToastText("Bug report sent!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <DialogHeader title={"Report A Bug"} />
      <form
        noValidate
        onSubmit={handleSubmit(() => {
          try {
            handleSubmitButtonClick();
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <Flex direction="column" gap="30px" style={{ minWidth: "40vw" }}>
          <Text color="secondary">
            This will send the developers an email with your bug details. Please
            be as descriptive as you can.
          </Text>
          <Flex direction="column" gap="8px">
            <FormField
              label="Page"
              error={errors && errors.page?.message}
              required
            >
              <TextInput
                required
                placeholder="Please enter the page(s) you found this bug on."
                value={watch(`page`)}
                {...register("page", {
                  required: "Please enter a page.",
                })}
              />
            </FormField>

            <FormField
              label="Bug details"
              error={errors && errors.bugDetails?.message}
              required
            >
              <Textarea
                style={{ resize: "none" }}
                placeholder="Describe the bug you found."
                value={watch("bugDetails")}
                {...register(`bugDetails`, {
                  required: "Please describe the bug.",
                  maxLength: {
                    value: 300,
                    message: "Message cannot be longer than 300 characters.",
                  },
                })}
              />
            </FormField>

            <FormField label="Steps to replicate">
              <Textarea
                style={{ resize: "none" }}
                placeholder="Describe the steps you took to replicate the bug (optional)."
                value={watch("stepsToReplicate")}
                {...register(`stepsToReplicate`, {
                  maxLength: {
                    value: 300,
                    message: "Message cannot be longer than 300 characters.",
                  },
                })}
              />
            </FormField>
          </Flex>
          <Button
            type={"primary"}
            width={"100px"}
            loading={isSubmitting}
            onClick={() => console.log("Submitted!")}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </>
  );
};

export default ReportBugModal;
