import { Page, Flex, Heading, Text } from "@tycholabs/armillary";
import { Content, ContentMain } from "@tycholabs/armillary";
import { Drum } from "lucide-react";
import { useTheme } from "styled-components";

export const Maintenance = () => {
  const theme = useTheme();

  return (
    <Flex direction="column" gap="0" style={{ height: "100vh" }}>
      <Page direction="row">
        <Content>
          <ContentMain scrollY="scroll">
            <Flex
              direction="column"
              justify="center"
              align="center"
              gap="40px"
              style={{ height: "100%" }}
            >
              <Flex
                direction="row"
                align="center"
                justify="center"
                width="auto"
                gap="30px"
              >
                <Flex
                  direction="column"
                  align="right"
                  justify="center"
                  width="auto"
                >
                  <Heading size="xLarge">
                    Sorry, we're down for maintenance.
                  </Heading>
                  <Text size="xLarge" color="secondary">
                    Please wait while we find our rhythm.
                  </Text>
                </Flex>
                <Drum size={90} color={theme.colors.text.link} />
              </Flex>
            </Flex>
          </ContentMain>
        </Content>
      </Page>
    </Flex>
  );
};

export default Maintenance;
