import {
  Flex,
  Heading,
  Card,
  FormField,
  Button,
  TextInput,
  Progress,
  Text,
} from "@tycholabs/armillary";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import { UsePostResetPass } from "../../hooks/reset";
import {
  getPasswordStrength,
  validatePasswordComplexity,
  getPasswordComplexity,
} from "../../utils";
import { Check, XIcon } from "lucide-react";

export interface ResetPasswordCodeProps {
  resetEmail: string;
  resetCode: string;
  resetKey: string;
  setPassSuccess: Dispatch<SetStateAction<boolean>>;
}

export interface ResetFormValues {
  resetPass: string;
  resetPassSecond: string;
}

export const ResetPasswordEnter = ({
  resetEmail,
  resetCode,
  resetKey,
  setPassSuccess,
}: ResetPasswordCodeProps) => {
  const theme = useTheme();

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetFormValues>({
    defaultValues: {
      resetPass: "",
      resetPassSecond: "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const resetPass = UsePostResetPass({
    resetEmail: resetEmail,
    resetCode: resetCode,
    resetKey: resetKey,
    resetPass: getValues("resetPass"),
  });

  const handleSendPassClick = () => {
    setIsSubmitting(true);
    resetPass.mutateAsync().then((res) => {
      setIsSubmitting(true);
      if (res.data === "Success") {
        setPassSuccess(true);
      } else {
        setIsSubmitting(false);
      }
    });
  };

  const passComplexity = getPasswordComplexity(watch(`resetPass`));

  return (
    <Flex direction='row' justify='center' align='center'>
      <Card style={{ width: "50%", minWidth: "400px" }}>
        <Heading>Reset Password</Heading>
        <form
          noValidate
          onSubmit={handleSubmit(() => {
            try {
              handleSendPassClick();
            } catch (e) {
              console.log(e);
            }
          })}
        >
          <Flex direction='column' gap='8px' align='center'>
            <FormField
              required
              label='Enter new password'
              width='325px'
              error={errors && errors.resetPass?.message}
            >
              <TextInput
                required
                type='password'
                value={watch(`resetPass`)}
                {...register("resetPass", {
                  required:
                    "Please enter a password that meets the following criteria:",
                  validate: (value) => {
                    return (
                      validatePasswordComplexity(value) ||
                      "Please enter a password that meets the following criteria:"
                    );
                  },
                })}
              />
            </FormField>
            <Flex direction='column' align='left' style={{ width: "325px" }}>
              <Flex direction='row'>
                {passComplexity.eightCharsOrGreater ? (
                  <Check size={16} color={theme.colors.palette.success} />
                ) : (
                  <XIcon size={16} color={theme.colors.text.error} />
                )}
                <Text
                  size='xSmall'
                  style={{
                    color: passComplexity.eightCharsOrGreater
                      ? theme.colors.palette.success
                      : theme.colors.text.error,
                  }}
                >
                  At least eight characters
                </Text>
              </Flex>
              <Flex direction='row'>
                {passComplexity.uppercase ? (
                  <Check size={16} color={theme.colors.palette.success} />
                ) : (
                  <XIcon size={16} color={theme.colors.text.error} />
                )}
                <Text
                  size='xSmall'
                  style={{
                    color: passComplexity.uppercase
                      ? theme.colors.palette.success
                      : theme.colors.text.error,
                  }}
                >
                  At least one uppercase letter
                </Text>
              </Flex>
              <Flex direction='row'>
                {passComplexity.lowercase ? (
                  <Check size={16} color={theme.colors.palette.success} />
                ) : (
                  <XIcon size={16} color={theme.colors.text.error} />
                )}
                <Text
                  size='xSmall'
                  style={{
                    color: passComplexity.lowercase
                      ? theme.colors.palette.success
                      : theme.colors.text.error,
                  }}
                >
                  At least one lowercase letter
                </Text>
              </Flex>
              <Flex direction='row'>
                {passComplexity.number ? (
                  <Check size={16} color={theme.colors.palette.success} />
                ) : (
                  <XIcon size={16} color={theme.colors.text.error} />
                )}
                <Text
                  size='xSmall'
                  style={{
                    color: passComplexity.number
                      ? theme.colors.palette.success
                      : theme.colors.text.error,
                  }}
                >
                  At least one number
                </Text>
              </Flex>
              <Flex direction='row'>
                {passComplexity.specialChar ? (
                  <Check size={16} color={theme.colors.palette.success} />
                ) : (
                  <XIcon size={16} color={theme.colors.text.error} />
                )}
                <Text
                  size='xSmall'
                  style={{
                    color: passComplexity.specialChar
                      ? theme.colors.palette.success
                      : theme.colors.text.error,
                  }}
                >
                  At least one special character (#, ?, !, @, $, %, ^, &, *, -)
                </Text>
              </Flex>
            </Flex>

            <FormField label='Strength' width='325px'>
              <Progress
                value={getPasswordStrength(watch("resetPass")) * 20}
                style={{ height: "10px" }}
              />
            </FormField>

            {getPasswordStrength(watch("resetPass")) >= 5 && (
              <FormField
                required
                label='Retype password'
                width='325px'
                error={errors && errors.resetPassSecond?.message}
              >
                <TextInput
                  required
                  type='password'
                  value={watch(`resetPassSecond`)}
                  {...register("resetPassSecond", {
                    required: "Please retype your password.",
                    validate: (value) => {
                      return (
                        value === watch("resetPass") ||
                        "Please retype your password."
                      );
                    },
                  })}
                />
              </FormField>
            )}
            <Button
              width='auto'
              type='primary'
              loading={isSubmitting}
              onClick={() => console.log("Submitted!")}
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Card>
    </Flex>
  );
};

export default ResetPasswordEnter;
