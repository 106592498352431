import {
  Flex,
  Heading,
  Card,
  TextInput,
  FormField,
  Button,
} from "@tycholabs/armillary";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { emailRegex } from "../../constants";
import { UsePostInitReset } from "../../hooks/reset";

export interface ResetPasswordEmailProps {
  setResetEmail: Dispatch<SetStateAction<string | null>>;
}

export interface ResetFormValues {
  resetEmail: string;
}

export const ResetPasswordEmail = ({
  setResetEmail,
}: ResetPasswordEmailProps) => {
  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetFormValues>({
    defaultValues: {
      resetEmail: undefined,
    },
  });

  const initReset = UsePostInitReset({ resetEmail: getValues("resetEmail") });

  const handleSendEmailClick = () => {
    initReset.mutateAsync().then((res) => {
      setResetEmail(getValues("resetEmail"));
    });
  };
  return (
    <Flex direction="row" justify="center" align="center">
      <Card style={{ width: "50%", minWidth: "400px" }}>
        <Heading>Reset Password</Heading>
        <form
          noValidate
          onSubmit={handleSubmit(() => {
            try {
              handleSendEmailClick();
            } catch (e) {
              console.log(e);
            }
          })}
        >
          <Flex direction="column" gap="8px" align="center">
            <FormField
              required
              label="Email"
              error={errors && errors.resetEmail?.message}
              width="250px"
            >
              <TextInput
                required
                type="email"
                value={watch(`resetEmail`)}
                {...register("resetEmail", {
                  required: "Please enter an email.",
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid email.",
                  },
                })}
              />
            </FormField>

            <Button
              width="auto"
              type="primary"
              onClick={() => console.log("Submitted!")}
            >
              Send Email
            </Button>
          </Flex>
        </form>
      </Card>
    </Flex>
  );
};

export default ResetPasswordEmail;
