import { Music, Calendar, Mail } from "lucide-react";
import { User } from "../../../constants/Types";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Flex,
  Heading,
  Text,
  Avatar,
  AvatarFallback,
  getUserColor,
  Separator,
} from "@tycholabs/armillary";
import { useTheme } from "styled-components";

export interface UserCardProps {
  user: User;
}

export const UserCard = ({ user }: UserCardProps) => {
  const theme = useTheme();
  return (
    <Card padding="20px" style={{ width: "280px" }}>
      <Flex direction="column" gap="16px">
        <Flex direction="row" gap="16px" align="center">
          <Avatar background={getUserColor(`${user.id}563hf`)} size="40px">
            <AvatarFallback>
              {`${user.firstName[0]}${user.lastName[0]}`}
            </AvatarFallback>
          </Avatar>
          <Heading size="small">
            {user.firstName} {user.middleName && user.middleName[0]}{" "}
            {user.lastName}
          </Heading>
        </Flex>

        <Flex direction="column" gap="8px">
          <Flex direction="row" gap="8px" align="center">
            <Mail size={18} color={theme.colors.text.secondary} />
            <Text color="secondary">{user.email}</Text>
          </Flex>

          <Flex direction="row" gap="8px" align="center">
            <Music size={18} color={theme.colors.text.secondary} />
            <Text color="secondary">
              <Text color="secondary">
                {user.instruments.length > 0 ? (
                  <>
                    {user.instruments.map((instrument, i) => (
                      <>
                        {instrument.name}
                        {i === user.instruments.length - 1 ? "" : ", "}
                      </>
                    ))}
                  </>
                ) : (
                  "-"
                )}
              </Text>
            </Text>
          </Flex>

          <Flex direction="row" gap="8px" align="center">
            <Calendar size={18} color={theme.colors.text.secondary} />
            <Text color="secondary">
              {user.groupId === 1 ? "Administrator" : "Teacher"}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex direction="column" gap="16px" style={{ flexGrow: 1 }} justify="end">
        <Separator />

        <Flex direction="row" gap="8px" width="100%">
          <Link style={{ textDecoration: "none" }} to={`/staff/${user.id}`}>
            <Button width="80px" type="secondary">
              View
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Card>
  );
};

export default UserCard;
