import {
  Card,
  CardGrid,
  DataOverlay,
  Flex,
  Heading,
  Button,
  Text,
  Dialog,
  DialogTrigger,
  DialogContent,
  Toast,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@tycholabs/armillary";
import { MoreHorizontal, Pencil, Plus, Trash } from "lucide-react";
import { UseEventsList } from "../../../hooks/event";
import { Event } from "../../../constants/Types";
import EventCard from "./EventCard";
import { useState } from "react";
import AddEventModal from "./AddEventModal";
import EditEventModal from "./EditEventModal";
import DeleteEventModal from "./DeleteEventModal";
import ViewEventModal from "./ViewEventModal";
import { useTheme } from "styled-components";

export const EventManagement = () => {
  const theme = useTheme();
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const permission = sessionStorage.getItem("permission");
  const session = sessionStorage.getItem("session");
  const eventResponse = UseEventsList(
    userId ?? "",
    businessId ?? "",
    session ?? ""
  );
  const eventsData: Event[] = eventResponse?.data?.data;

  const [viewEventModalOpen, setViewEventModalOpen] = useState(false);
  const [addEventModalOpen, setAddEventModalOpen] = useState(false);
  const [editEventModalOpen, setEditEventModalOpen] = useState(false);
  const [deleteEventModalOpen, setDeleteEventModalOpen] = useState(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("Saved!");

  const [eventToView, setEventToView] = useState<Event | null>(null);
  const [eventToEdit, setEventToEdit] = useState<Event | null>(null);
  const [eventToDelete, setEventToDelete] = useState<Event | null>(null);

  return (
    <Flex
      direction="column"
      gap="20px"
      style={{ maxWidth: "82vw", boxSizing: "border-box" }}
    >
      <Flex direction="column">
        <Heading size="medium">View events</Heading>
        <Heading size="xSmall" color="secondary">
          Add or edit upcoming events here.
        </Heading>
      </Flex>
      <Card style={{ width: "100%" }}>
        <DataOverlay
          loading={eventResponse.isLoading}
          error={eventResponse.isError}
          exists={eventsData !== null}
        >
          <CardGrid>
            {eventsData && eventsData.length > 0 && (
              <>
                {eventsData.map((event) => (
                  <Flex
                    width="24%"
                    style={{
                      minWidth: "250px",
                      maxWidth: "24%",
                      height: "220px",
                      position: "relative",
                    }}
                  >
                    <Button
                      type="outline"
                      height="100%"
                      style={{
                        textDecoration: "none",
                        borderRadius: "12px",
                        boxShadow: theme.colors.shadow.small,
                        flexDirection: "row",
                        justifyContent: "left",
                      }}
                      padding={"30px"}
                      onClick={() => {
                        setEventToView(event);
                        setViewEventModalOpen(true);
                      }}
                    >
                      <EventCard event={event} />
                    </Button>
                    {permission === "1" && (
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            style={{
                              position: "absolute",
                              zIndex: 50,
                              bottom: 5,
                              right: 5,
                            }}
                            width="36px"
                            height="36px"
                            padding="0px"
                            type="ghost"
                          >
                            <MoreHorizontal width="24px" height="24px" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuPortal>
                          <DropdownMenuContent>
                            <DropdownMenuLabel>Event options</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              onClick={() => {
                                setEventToEdit(event);
                                setEditEventModalOpen(true);
                              }}
                            >
                              <Pencil width="16px" height="16px" />
                              Edit
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              onClick={() => {
                                setEventToDelete(event);
                                setDeleteEventModalOpen(true);
                              }}
                            >
                              <Trash width="16px" height="16px" />
                              Delete
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenuPortal>
                      </DropdownMenu>
                    )}
                  </Flex>
                ))}
              </>
            )}
            {permission === "1" && (
              <Dialog
                open={addEventModalOpen}
                onOpenChange={setAddEventModalOpen}
              >
                <DialogTrigger asChild>
                  <Button
                    type="outline"
                    height="100%"
                    padding={"30px"}
                    style={{
                      borderStyle: "dashed",
                      borderWidth: "2px",
                      textDecoration: "none",
                      width: "24%",
                      minWidth: "250px",
                      maxWidth: "24%",
                      height: "220px",
                      borderRadius: "12px",
                      boxShadow: theme.colors.shadow.small,
                    }}
                  >
                    <Flex
                      direction={"row"}
                      gap={"8px"}
                      align="center"
                      style={{ margin: "auto" }}
                    >
                      <Plus
                        width={"36px"}
                        height={"36px"}
                        color={theme.colors.text.secondary}
                      />
                      <Text size="xLarge" weight="bold" color="secondary">
                        Add event
                      </Text>
                    </Flex>
                  </Button>
                </DialogTrigger>
                <DialogContent
                  onPointerDownOutside={(e: any) => e.preventDefault()}
                >
                  <AddEventModal
                    setAddEventModalOpen={setAddEventModalOpen}
                    setToastVisible={setToastVisible}
                    setToastText={setToastText}
                    queryRefetch={eventResponse.refetch}
                  />
                </DialogContent>
              </Dialog>
            )}
            {permission !== "1" &&
              (eventsData === undefined || eventsData.length <= 0) && (
                <DataOverlay
                  exists={false}
                  subtitle="Ask an administrator to add an event."
                >
                  <></>
                </DataOverlay>
              )}
          </CardGrid>
        </DataOverlay>
      </Card>
      <Toast title={toastText} open={toastVisible} />
      <Dialog open={viewEventModalOpen} onOpenChange={setViewEventModalOpen}>
        <DialogContent>
          <ViewEventModal event={eventToView} />
        </DialogContent>
      </Dialog>
      <Dialog open={editEventModalOpen} onOpenChange={setEditEventModalOpen}>
        <DialogContent onPointerDownOutside={(e: any) => e.preventDefault()}>
          <EditEventModal
            setEditEventModalOpen={setEditEventModalOpen}
            setToastVisible={setToastVisible}
            setToastText={setToastText}
            queryRefetch={eventResponse.refetch}
            event={eventToEdit}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteEventModalOpen}
        onOpenChange={setDeleteEventModalOpen}
      >
        <DialogContent>
          <DeleteEventModal
            setDeleteEventModalOpen={setDeleteEventModalOpen}
            setToastVisible={setToastVisible}
            setToastText={setToastText}
            queryRefetch={eventResponse.refetch}
            event={eventToDelete}
          />
        </DialogContent>
      </Dialog>
    </Flex>
  );
};

export default EventManagement;
