import { DaysOfTheWeek, DaysOfTheWeekShort } from "../constants";

export const getDaysList = (days: string[], short?: boolean) => {
  const uniqueDays = Array.from(new Set(days.flat()));
  return uniqueDays.sort().map((day, i) => {
    return `${
      short ? DaysOfTheWeekShort[parseInt(day)] : DaysOfTheWeek[parseInt(day)]
    }${i === uniqueDays.length - 1 ? "" : ", "}`;
  });
};

export const convertTo12Hr = (time: string, short?: boolean) => {
  const [hours, minutes] = time.split(":");
  const hoursToNum = parseInt(hours);
  if (hoursToNum > 12) {
    return `${hoursToNum - 12}:${minutes}${short ? "" : " PM"}`;
  } else {
    return `${time}${short ? "" : " AM"}`;
  }
};

export const convertDate = (date: string) => {
  const convDate = new Date(date);
  const userTimezoneOffset = convDate.getTimezoneOffset() * 60000;
  return new Date(convDate.getTime() + userTimezoneOffset).toDateString();
};

export const getPasswordComplexity = (pass: string) => {
  const atLeastOneUppercase = /[A-Z]/g;
  const atLeastOneLowercase = /[a-z]/g;
  const atLeastOneNumeric = /[0-9]/g;
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g;
  const eightCharsOrMore = /.{8,}/g;

  return {
    uppercase: pass.match(atLeastOneUppercase) ? true : false,
    lowercase: pass.match(atLeastOneLowercase) ? true : false,
    number: pass.match(atLeastOneNumeric) ? true : false,
    specialChar: pass.match(atLeastOneSpecialChar) ? true : false,
    eightCharsOrGreater: pass.match(eightCharsOrMore) ? true : false,
  };
};

export const getPasswordStrength = (pass: string) => {
  const strength = Object.values(getPasswordComplexity(pass)).reduce(
    (a, item) => a + (item === true ? 1 : 0),
    0
  );
  return strength;
};

export const validatePasswordComplexity = (pass: string) => {
  if (getPasswordStrength(pass) >= 5) {
    return true;
  } else {
    return false;
  }
};

export const stringToNumber = (s: string): number => {
  // https://stackoverflow.com/questions/14667713/how-to-convert-a-string-to-number-in-typescript
  return ~~s;
};
