import { Page, Flex, Heading, Text, Button } from "@tycholabs/armillary";
import { Content, ContentMain } from "@tycholabs/armillary";
import { SearchSlash } from "lucide-react";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";

export const NotFound = () => {
  const theme = useTheme();

  return (
    <Flex direction="column" gap="0" style={{ height: "100vh" }}>
      <Page direction="row">
        <Content>
          <ContentMain scrollY="scroll">
            <Flex
              direction="column"
              justify="center"
              align="center"
              gap="40px"
              style={{ height: "100%" }}
            >
              <Flex
                direction="row"
                align="center"
                justify="center"
                width="auto"
                gap="30px"
              >
                <Flex
                  direction="column"
                  align="right"
                  justify="center"
                  width="auto"
                >
                  <Heading size="xLarge">404 Not Found</Heading>
                  <Text size="xLarge" color="secondary">
                    I'm not sure what you were looking for, but this probably
                    wasn't it.
                  </Text>
                </Flex>
                <SearchSlash size={90} color={theme.colors.text.link} />
              </Flex>
              <Link to="/">
                <Button width="100px" type="primary">
                  Home
                </Button>
              </Link>
            </Flex>
          </ContentMain>
        </Content>
      </Page>
    </Flex>
  );
};

export default NotFound;
