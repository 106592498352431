import { Button, DataTableColumnHeader, Flex } from "@tycholabs/armillary";
import { Trash } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import { Note } from "../../../../constants/Types";

export const getPrivateNotesColumns = (
  setPrivateNoteToDelete: Dispatch<SetStateAction<Note | null>>,
  setDeletePrivateNoteModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  return [
    {
      id: "teacher.name",
      enableSorting: false,
      accessorKey: "teacher.name",
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Teacher" />
      ),
    },
    {
      id: "noteText",
      enableSorting: false,
      accessorKey: "noteText",
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Note" />
      ),
    },
    {
      id: "date",
      accessorKey: "date",
      header: ({ column }: any) => (
        <DataTableColumnHeader column={column} title="Date" justify="right" />
      ),
      cell: ({ row }: any) => {
        return (
          <Flex justify="left">
            {new Date(row.original.date).toDateString()}
          </Flex>
        );
      },
    },
    {
      id: "actions",
      enableSorting: false,
      cell: ({ row }: any) => {
        return (
          <Button
            width="auto"
            type="destructive"
            onClick={() => {
              setPrivateNoteToDelete(row.original);
              setDeletePrivateNoteModalOpen(true);
            }}
          >
            <Trash width="16px" height="16px" />
          </Button>
        );
      },
    },
  ];
};
