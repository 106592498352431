import {
  Flex,
  Heading,
  Card,
  DataOverlay,
  FormField,
  Button,
  Toast,
  Text,
  DataTable,
  Dialog,
  DialogContent,
  Textarea,
} from "@tycholabs/armillary";
import { Note } from "../../../../constants/Types";
import {
  UsePutUpdateParentNote,
  UseParentNote,
  UsePrivateNotesList,
} from "../../../../hooks/notes";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getPrivateNotesColumns } from "./PrivateNotesColumns";
import AddPrivateNoteModal from "./AddPrivateNoteModal";
import DeletePrivateNoteModal from "./DeletePrivateNoteModal";

export interface NotesViewProps {
  lesson: string;
}

export interface EditParentNoteFormValues {
  noteText: string;
}

export const NotesView = ({ lesson }: NotesViewProps) => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const session = sessionStorage.getItem("session");
  const parentNoteResponse = UseParentNote(
    userId ?? "",
    businessId ?? "",
    lesson,
    session ?? ""
  );
  const parentNoteData: Note = parentNoteResponse?.data?.data[0];
  const privateNotesResponse = UsePrivateNotesList(
    userId ?? "",
    businessId ?? "",
    lesson,
    session ?? ""
  );
  const privateNotesData: Note[] = privateNotesResponse?.data?.data;

  const [addPrivateNoteModalOpen, setAddPrivateNoteModalOpen] = useState(false);
  const [deletePrivateNoteModalOpen, setDeletePrivateNoteModalOpen] =
    useState(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("Saved!");

  const [privateNoteToDelete, setPrivateNoteToDelete] = useState<Note | null>(
    null
  );
  const [isParentNoteEditing, setIsParentNoteEditing] = useState(false);

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<EditParentNoteFormValues>({
    defaultValues: {
      noteText: parentNoteData ? parentNoteData.noteText : undefined,
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const putUpdateParentNote = UsePutUpdateParentNote(
    userId ?? "",
    businessId ?? "",
    lesson,
    {
      userId: `${userId}`,
      noteText: getValues("noteText"),
    },
    session ?? ""
  );

  useEffect(() => {
    if (parentNoteData !== undefined) {
      setValue("noteText", parentNoteData.noteText);
    }
  }, [parentNoteData]);

  const handleSubmitButtonClick = () => {
    setIsSubmitting(true);
    putUpdateParentNote.mutateAsync().then((res) => {
      if (res.data === "Success") {
        setIsSubmitting(false);
        parentNoteResponse.refetch();
        setIsParentNoteEditing(false);
        setToastText("Note to parents updated!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      } else {
        setIsSubmitting(false);
        setToastText("Error!");
        setToastVisible(true);
        const timeId = setTimeout(() => {
          setToastVisible(false);
        }, 3000);
        return () => {
          clearTimeout(timeId);
        };
      }
    });
  };

  return (
    <>
      <Card>
        <Flex direction="column" gap="0">
          <Heading>Lesson notes</Heading>
          <Heading color="secondary" size="small" weight="semibold">
            View parent notes, private notes, and student goals.
          </Heading>
        </Flex>

        <Card>
          <form
            noValidate
            onSubmit={handleSubmit(() => {
              try {
                handleSubmitButtonClick();
              } catch (e) {
                console.log(e);
              }
            })}
          >
            <Flex direction="column" gap="8px">
              <Flex direction="row" justify="space-between">
                <Flex direction="column" gap="0">
                  <Heading size="small">Note to parents</Heading>
                  <Heading size="xSmall" color="secondary" weight="semibold">
                    This note will be visible in emails to parents.
                  </Heading>
                </Flex>

                <Button
                  type="primary"
                  disabled={!isParentNoteEditing}
                  loading={isSubmitting}
                  width="auto"
                  onClick={() => console.log("Submitted!")}
                >
                  Save
                </Button>
              </Flex>
              <DataOverlay
                loading={
                  parentNoteResponse.isLoading ||
                  parentNoteResponse.isRefetching
                }
                error={parentNoteResponse.isError}
                exists={parentNoteData !== null}
              >
                <Flex direction="column">
                  {parentNoteData && (
                    <Text size="small" color="secondary">
                      Last edited by: {parentNoteData.teacher.name},{" "}
                      {new Date(parentNoteData.date).toDateString()}
                    </Text>
                  )}
                  <FormField
                    label=""
                    error={errors && errors.noteText?.message}
                    limit={{
                      value: watch(`noteText`) ? watch("noteText").length : 0,
                      cap: 500,
                    }}
                  >
                    <Textarea
                      style={{ resize: "none", height: "120px" }}
                      maxLength={500}
                      placeholder="Enter your note to parents here."
                      onInput={() => setIsParentNoteEditing(true)}
                      value={watch("noteText")}
                      {...register(`noteText`, {
                        required: "Please enter a note.",
                        maxLength: {
                          value: 500,
                          message: "Note cannot be longer than 500 characters.",
                        },
                      })}
                    />
                  </FormField>
                </Flex>
              </DataOverlay>
            </Flex>
          </form>
        </Card>

        <Card style={{ gap: "8px" }}>
          <Flex direction="row" justify="space-between">
            <Flex direction="column" gap="0">
              <Heading size="small">Private notes</Heading>
              <Heading size="xSmall" color="secondary" weight="regular">
                These notes are for the school's eyes only, and are never
                visible to parents.
              </Heading>
            </Flex>
            <Button
              width="auto"
              type="secondary"
              onClick={() => setAddPrivateNoteModalOpen(true)}
            >
              Add
            </Button>
          </Flex>

          <DataOverlay
            loading={privateNotesResponse.isLoading}
            error={privateNotesResponse.isError}
            exists={privateNotesData && privateNotesData.length > 0}
          >
            {privateNotesData && (
              <DataTable
                pagination
                columns={getPrivateNotesColumns(
                  setPrivateNoteToDelete,
                  setDeletePrivateNoteModalOpen
                )}
                data={privateNotesData.sort((a, b) =>
                  b.date > a.date ? 1 : a.date > b.date ? -1 : 0
                )}
              />
            )}
          </DataOverlay>
        </Card>
      </Card>
      <Toast title={toastText} open={toastVisible} />
      <Dialog
        open={addPrivateNoteModalOpen}
        onOpenChange={setAddPrivateNoteModalOpen}
      >
        <DialogContent onPointerDownOutside={(e: any) => e.preventDefault()}>
          <AddPrivateNoteModal
            setAddPrivateNoteModalOpen={setAddPrivateNoteModalOpen}
            setToastVisible={setToastVisible}
            setToastText={setToastText}
            queryRefetch={privateNotesResponse.refetch}
            lesson={lesson}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={deletePrivateNoteModalOpen}
        onOpenChange={setDeletePrivateNoteModalOpen}
      >
        <DialogContent>
          <DeletePrivateNoteModal
            setDeletePrivateNoteModalOpen={setDeletePrivateNoteModalOpen}
            setToastVisible={setToastVisible}
            setToastText={setToastText}
            queryRefetch={privateNotesResponse.refetch}
            note={privateNoteToDelete}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NotesView;
