import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endpoint } from "../constants/Endpoints";
import { AddPieceDTO, DeletePieceDTO, UpdatePieceDTO } from "../constants/DTO";

const GetPiecesList = async (
  userId: string,
  businessId: string,
  lessonId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/pieces`, {
      params: {
        user: userId,
        business: businessId,
        lesson: lessonId,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

const GetPiecesAutofill = async (
  userId: string,
  businessId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/pieces/autofill`, {
      params: {
        user: userId,
        business: businessId,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UsePiecesList = (
  userId: string,
  businessId: string,
  lessonId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-${lessonId}-GetPiecesList`],
    queryFn: () => GetPiecesList(userId, businessId, lessonId, sessionId),
  });
  return response;
};

const GetPiecesListByEvent = async (
  userId: string,
  businessId: string,
  eventId: string,
  sessionId: string
) => {
  return await axios
    .get(`${endpoint}/pieces`, {
      params: {
        user: userId,
        business: businessId,
        event: eventId,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UsePiecesListByEvent = (
  userId: string,
  businessId: string,
  eventId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-${eventId}-GetPiecesListByEvent`],
    queryFn: () => GetPiecesListByEvent(userId, businessId, eventId, sessionId),
  });
  return response;
};

export const UsePiecesAutofill = (
  userId: string,
  businessId: string,
  sessionId: string
) => {
  const response = useQuery({
    queryKey: [`${userId}-${businessId}-PiecesAutofill`],
    queryFn: () => GetPiecesAutofill(userId, businessId, sessionId),
  });
  return response;
};

const PostNewPiece = async (
  userId: string,
  businessId: string,
  piece: AddPieceDTO,
  sessionId: string
) => {
  return await axios
    .post(
      `${endpoint}/pieces`,
      {
        piece: piece,
      },
      {
        params: {
          user: userId,
          business: businessId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePostNewPiece = (
  userId: string,
  businessId: string,
  piece: AddPieceDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PostNewPiece`],
    mutationFn: () => PostNewPiece(userId, businessId, piece, sessionId),
  });
  console.log(response);
  return response;
};

const PutUpdatePiece = async (
  userId: string,
  businessId: string,
  piece: UpdatePieceDTO,
  sessionId: string
) => {
  return await axios
    .put(
      `${endpoint}/pieces`,
      {
        piece: piece,
      },
      {
        params: {
          user: userId,
          business: businessId,
          session: sessionId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UsePutUpdatePiece = (
  userId: string,
  businessId: string,
  piece: UpdatePieceDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-PutUpdatePiece`],
    mutationFn: () => PutUpdatePiece(userId, businessId, piece, sessionId),
  });
  return response;
};

const DeletePiece = async (
  userId: string,
  businessId: string,
  piece: DeletePieceDTO,
  sessionId: string
) => {
  return await axios
    .delete(`${endpoint}/pieces`, {
      data: {
        user: userId,
        business: businessId,
        piece: piece,
        session: sessionId,
      },
    })
    .then((res) => {
      return res;
    });
};

export const UseDeletePiece = (
  userId: string,
  businessId: string,
  piece: DeletePieceDTO,
  sessionId: string
) => {
  const response = useMutation({
    mutationKey: [`${userId}-${businessId}-DeletePiece`],
    mutationFn: () => DeletePiece(userId, businessId, piece, sessionId),
  });
  return response;
};
