import {
  Card,
  DataTable,
  DataOverlay,
  Flex,
  Heading,
  Dialog,
  DialogTrigger,
  Button,
  DialogContent,
  Toast,
} from "@tycholabs/armillary";
import { UseLessonsList } from "../../../hooks/lesson";
import { Lesson } from "../../../constants/Types";
import { getLessonsColumns } from "./LessonsColumns";
import AddLessonModal from "./AddLessonModal";
import { useState } from "react";
import DeleteLessonModal from "./DeleteLessonModal";

const getUserName = (lessonsData: Lesson[], userId: string) => {
  if (lessonsData !== undefined) {
    const lessonsByUserId = lessonsData.filter(
      (less) => less.teacher.id == userId
    );
    if (lessonsByUserId.length > 0) {
      return lessonsByUserId[0].teacher.name;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const Lessonmanagement = () => {
  const userId = sessionStorage.getItem("userId");
  const businessId = sessionStorage.getItem("businessId");
  const permission = sessionStorage.getItem("permission");
  const session = sessionStorage.getItem("session");
  const lessonResponse = UseLessonsList(
    userId ?? "",
    businessId ?? "",
    session ?? ""
  );
  const lessonsData: Lesson[] = lessonResponse?.data?.data;

  const [addLessonModalOpen, setAddLessonModalOpen] = useState(false);
  const [deleteLessonModalOpen, setDeleteLessonModalOpen] = useState(false);
  const [lessonToDelete, setLessonToDelete] = useState<Lesson | null>(null);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("Saved!");

  return (
    <Flex
      direction="column"
      gap="20px"
      style={{ maxWidth: "82vw", boxSizing: "border-box" }}
    >
      <Flex direction="row">
        <Flex direction="column">
          <Heading size="medium">Lessons</Heading>
        </Flex>
        {permission === "1" && (
          <Dialog
            open={addLessonModalOpen}
            onOpenChange={setAddLessonModalOpen}
          >
            <DialogTrigger asChild>
              <Button type="primary" width="120px">
                Add lesson
              </Button>
            </DialogTrigger>
            <DialogContent
              onPointerDownOutside={(e: any) => e.preventDefault()}
            >
              <AddLessonModal
                setAddLessonModalOpen={setAddLessonModalOpen}
                setToastVisible={setToastVisible}
                setToastText={setToastText}
                queryRefetch={lessonResponse.refetch}
              />
            </DialogContent>
          </Dialog>
        )}
      </Flex>

      <Card>
        <DataOverlay
          loading={lessonResponse.isLoading}
          error={lessonResponse.isError}
          exists={lessonsData && lessonsData.length > 0}
        >
          {lessonsData && (
            <DataTable
              searchFilters={[
                {
                  value: "student.name",
                  placeholder: "Search students",
                  width: "200px",
                },
              ]}
              selectFilters={[
                {
                  title: "Teachers",
                  column: "teacher.name",
                  options: lessonsData
                    .map((lesson) => {
                      return {
                        value: lesson.teacher.name,
                        text: lesson.teacher.name,
                      };
                    })
                    .filter(
                      (value, index, self) =>
                        index ===
                        self.findIndex(
                          (t) =>
                            t.value === value.value && t.text === value.text
                        )
                    ),
                },
                {
                  title: "Days",
                  column: "days",
                  options: [
                    {
                      value: "0",
                      text: "Sunday",
                    },
                    {
                      value: "1",
                      text: "Monday",
                    },
                    {
                      value: "2",
                      text: "Tuesday",
                    },
                    {
                      value: "3",
                      text: "Wednesday",
                    },
                    {
                      value: "4",
                      text: "Thursday",
                    },
                    {
                      value: "5",
                      text: "Friday",
                    },
                    {
                      value: "6",
                      text: "Saturday",
                    },
                  ],
                },
              ]}
              initialFilters={
                getUserName(lessonsData, `${userId}`)
                  ? [
                      {
                        column: "teacher.name",
                        values: [getUserName(lessonsData, `${userId}`)],
                      },
                      {
                        column: "days",
                        values: [`${new Date().getDay()}`],
                      },
                    ]
                  : [
                      {
                        column: "days",
                        values: [`${new Date().getDay()}`],
                      },
                    ]
              }
              pagination
              columnSelection
              columns={getLessonsColumns(
                setLessonToDelete,
                setDeleteLessonModalOpen,
                permission
              )}
              data={lessonsData}
            />
          )}
        </DataOverlay>
      </Card>
      <Dialog
        open={deleteLessonModalOpen}
        onOpenChange={setDeleteLessonModalOpen}
      >
        <DialogContent onPointerDownOutside={(e: any) => e.preventDefault()}>
          <DeleteLessonModal
            setDeleteLessonModalOpen={setDeleteLessonModalOpen}
            setToastVisible={setToastVisible}
            setToastText={setToastText}
            queryRefetch={lessonResponse.refetch}
            lesson={lessonToDelete}
          />
        </DialogContent>
      </Dialog>
      <Toast title={toastText} open={toastVisible} />
    </Flex>
  );
};

export default Lessonmanagement;
